import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {useHistory} from "react-router-dom";
import {Box} from "@mui/material";

import {getLangPath} from "../../../../../helpers/multilingual";
import {getFormalizedName} from "../../helpers/helper";
import { shopActions } from "../../store/actions";
import {sessionSelector} from "../../store/selectors";

import ProductsAutocomplete from "./ProductsAutocomplete";

const SearchBarButton = ({autoFocus}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const session = useSelector(sessionSelector);
  const [search, setSearch] = useState('');

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSelectProduct = (product) => {

    dispatch(shopActions.searchDrawerOpen(false));
    dispatch(shopActions.setFromInternalNavigation(true));
    sessionStorage.setItem("fromInternalNavigation", "true");
    history.push(`${getLangPath()}/shop/product/${getFormalizedName(product.reference)}/${product.id}`);
  };

  const handleEnter = () => {
    if (search && search?.length > 1) {
      dispatch(shopActions.searchDrawerOpen(false));
      dispatch(shopActions.setFromInternalNavigation(true));
      sessionStorage.setItem("fromInternalNavigation", "true");
      history.push(`${getLangPath()}/shop/search/${search}`);
    }
  };

  return (
    <Box
      sx={{
        width: '290px',
        '& .MuiInputBase-root.MuiOutlinedInput-root': {
          borderRadius: '30px',
          height: '43px',
          backgroundColor: '#e8e8e8',
        },
        '& .MuiInputBase-input': {
          padding: '0.5px 4px 7.5px 6px !important',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          // border: '1px solid',
        }
      }}>
      <ProductsAutocomplete
        variant={'outlined'}
        autoFocus={autoFocus}
        country={session?.country || 'LU'}
        onSelect={handleSelectProduct}
        onChange={handleChange}
        onEnter={handleEnter}
        enableEnterKeyDown={true}
      />
    </Box>
  );
};

export default SearchBarButton;
