import React, {useContext, useEffect, useState} from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Badge from "@mui/material/Badge";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { Link } from "../../../helpers/multilingual";

import { MenuContext } from "./Menu";

const MenuItem = ({title, icon, notificationsCount = 0, to, expandTo, hidden, children, setMobileOpen, sx}) => {
  const { pathname, onItemSelected } = useContext(MenuContext);
  const [expended, setExpended] = useState(false);

  useEffect(() => {
    setExpended(
      expandTo && (
        (Array.isArray(expandTo) && (pathname.includes(expandTo[0]) || pathname.includes(expandTo[1]) || pathname.includes(expandTo[2]))) ||
                pathname.includes(expandTo)
      )
    );
  }, [pathname]);

  const isActive = pathname === to || expended;

  const handleToggleExpend = () => {
    setExpended(!expended);
  };

  const handleMobileClose = () => {
    if(setMobileOpen) setMobileOpen(false);
    if(onItemSelected) onItemSelected();
  };

  if(hidden) {
    return null;
  }

  return (
    <React.Fragment>
      <ListItemButton
        className={isActive ? 'active' : 'inactive'}
        onClick={children ? handleToggleExpend : handleMobileClose}
        component={children ? 'div' : Link}
        to={to}
        sx={sx}
      >
        <ListItemIcon>
          {notificationsCount > 0 && (
            <Badge
              color="error"
              badgeContent={notificationsCount}
            >
              {icon}
            </Badge>
          )}
          {!notificationsCount && icon}
        </ListItemIcon>
        <ListItemText primary={title} />
        {children && (
          expended ? <ExpandLess /> : <ExpandMore />
        )}
      </ListItemButton>
      {children && (
        <Collapse addEndListener={() => {}} in={expended} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children}
          </List>
        </Collapse>
      )}
      <Divider/>
    </React.Fragment>
  );
};
export default MenuItem;
