import React from "react";
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
import {Box} from "@mui/material";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";

import theme from "../../../assets/styles/theme/theme";
import {Link} from "../../../helpers/multilingual";
import Menu from "../Menu/Menu";
import MenuItem from "../Menu/MenuItem";

const MenuDrawer = () => (
  <div>
    <Toolbar>
      <Link to="/pro/commissions/sales">
        <Box component={'img'} width={50} src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/logo/logo.svg`} alt="logo"
          sx={{width: '100%'}}/>
      </Link>
    </Toolbar>
    <Divider/>
    <Menu sx={{
      '& .MuiListItemText-primary': {
        fontFamily: 'Open sans',
        color: 'black'
      },
      '& .MuiListItemIcon-root': {
        color: theme.palette.primary.main
      }
    }}>
      <MenuItem
        title={"Commandes patients"}
        to={"/pro/commissions/sales"}
        icon={<MonetizationOnTwoToneIcon/>}
      />{/*
        <MenuItem
          title={"Mes prescriptions"}
          expandTo={'/pro/prescriptions'}
          icon={<ArticleIcon/>}
          hidden={!isDoctorOrTherap}
          //notificationsCount={customerRedirectionsCount}
        >*/}
      <MenuItem
        title={"Toutes les prescriptions"}
        to={"/pro/prescriptions"}
        icon={<AssignmentIcon/>}
      />
      {/*<MenuSubItem
            title={"Prescriptions abouties"}
            to={"/pro/prescriptions/success"}
            icon={<AssignmentTurnedInIcon />}
          />*/}
      {/*<MenuItem
          title={"Mes patients"}
          expandTo={'/pro/customer'}
          icon={<SwitchAccountIcon/>}
          hidden={!isDoctorOrTherap}
          //notificationsCount={customerRedirectionsCount}
        >*/}
      <MenuItem
        title={"Liste des patients"}
        to={"/pro/customers"}
        icon={<AssignmentIndIcon/>}
      />
      {/*<MenuSubItem
            title={"Ajouter un patient"}
            to={"/pro/customer/add"}
            icon={<MonetizationOnTwoToneIcon />}
            hidden
          />*/}
    </Menu>
  </div>
);

export default MenuDrawer;