import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  DataGrid as MuiDataGrid,
  enUS,
  frFR,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

import DotsLoader from "../DotsLoader";

import GridToolbarQuickSearch from "./GridToolbarQuickSearch";

const GridToolbarExport = ({ csvOptions, printOptions, onCsvMouseUp, ...other}) => (
  <GridToolbarExportContainer {...other}>
    <GridCsvExportMenuItem options={csvOptions} />
    <GridPrintExportMenuItem options={printOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = (params) => {

  if(
    !params.columns.enabled
        && !params.filter.enabled
        && !params.density.enabled
        && !params.export.enabled
        && !params.search.enabled
  ) {
    return null;
  }
  return (
    <GridToolbarContainer>
      {params.columns.enabled && (
        <GridToolbarColumnsButton {...params.columns.options} />
      )}
      {params.filter.enabled && (
        <GridToolbarFilterButton {...params.filter.options} />
      )}
      {params.density.enabled && (
        <GridToolbarDensitySelector {...params.density.options} />
      )}
      {params.export.enabled && (
        <GridToolbarExport {...params.export.options} />
      )}
      {params.search.enabled && (
        <GridToolbarQuickSearch {...params.search.options}/>
      )}
    </GridToolbarContainer>
  );
};

const DataGrid = ({
  rowHeight= 52,
  data = [],
  sx = {},
  onRowClick = () => {},
  initialState = {},
  initialPageSize = 25,
  rowsPerPageOptions= [5, 10, 25, 50, 100],
  columns,
  loading= false,
  toolbarQuickSearch = false,
  toolbarColumns = false,
  toolbarColumnsOptions = {},
  toolbarFilter = false,
  toolbarFilterOptions = {},
  toolbarDensity = false,
  toolbarDensityOptions = {},
  toolbarExportCSV = false,
  toolbarExportCSVOptions = {},

  toolbarExportPrint = false,
  toolbarExportPrintOptions = {},
  onCsvMouseUp,
  onlyExportSelectedRows = false,
  toolBarQuickSearchNestedObjects = [],
  toolBarQuickSearchTranslations = {},
  ...rest
}) => {

  const {i18n} = useTranslation();
  const [lang, setLang] = useState(enUS);
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [searchText, setSearchText] = useState('');
  const [selectionModel, setSelectionModel] = React.useState([]);

  useEffect(() => {
    if(data) {
      setRows(data);
    }
  }, [data]);

  useEffect(() => {
    switch (i18n.language) {
      case 'fr':
        setLang(frFR);
        break;
      default:
        setLang(enUS);
    }
  }, [i18n.language]);

  if(!data) {
    return (
      <Box>
        <Typography variant="h6" component="div" sx={{textAlign: 'center', mt: 2, color: "primary.main"}}>
            Aucune donnée à afficher
        </Typography>
        <DotsLoader/>
      </Box>
    );
  }

  return (
    <MuiDataGrid
      sx={theme => ({
        '& .MuiDataGrid-cell:focus': {
          outline: 'none'
        },
        '& .MuiDataGrid-toolbarContainer': {
          borderRadius: theme.spacing(2, 2, 0, 0),
          padding: theme.spacing(1, 2, 1, 2)
        },
        ...sx
      })}
      rows={rows}
      autoHeight
      pagination
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={rowsPerPageOptions}
      pageSize={pageSize}
      initialState={initialState}
      rowHeight={rowHeight}
      onRowClick={onRowClick}
      disableSelectionOnClick
      loading={loading}
      onSelectionModelChange={(newSelectionModel) => {
        setSelectionModel(newSelectionModel);
      }}
      selectionModel={selectionModel}
      components={{ Toolbar: CustomToolbar }}
      componentsProps={{
        toolbar: {
          search: {
            enabled: toolbarQuickSearch,
            options: {
              value: searchText,
              data,
              setRows,
              setSearchText,
              toolBarQuickSearchNestedObjects,
              toolBarQuickSearchTranslations
            }
          },
          columns: {
            enabled: toolbarColumns,
            options: {
              ...toolbarColumnsOptions
            }
          },
          filter: {
            enabled: toolbarFilter,
            options: {
              ...toolbarFilterOptions
            }
          },
          density: {
            enabled: toolbarDensity,
            options: {
              ...toolbarDensityOptions
            }
          },
          export: {
            enabled: toolbarExportCSV || toolbarExportPrint,
            options: {
              csvOptions: {
                disableToolbarButton: !toolbarExportCSV,
                delimiter: ';',
                utf8WithBom: true,
                allColumns: true,
                selectedRows:selectionModel,
                ...toolbarExportCSVOptions
              },
              onCsvMouseUp:onCsvMouseUp,
              printOptions: {
                disableToolbarButton: !toolbarExportPrint,
                allColumns: true,
                ...toolbarExportPrintOptions
              },
            }
          }
        },
      }}
      columns={columns.map((column, key) => ({
        ...column,
        ...column.tooltip && !column.renderCell && !column.getActions
          ? {
            renderCell: (params) => (
              <Tooltip key={key} title={column.formatter ? column.formatter(params.value) : params.value} placement="bottom">
                <Box>
                  {column.formatter ? column.formatter(params.value) : params.value}
                </Box>
              </Tooltip>
            )
          }
          : {},
        ...!column.tooltip && !column.renderCell && !column.getActions
          ? {
            renderCell: (params) => (
              column.formatter ? column.formatter(params.value) : params.value
            ),
          }
          : {},
        valueFormatter: column.valueFormatter ? column.valueFormatter : ({ value }) => column.formatter ? column.formatter(value) : value,
      }))}
      localeText={lang.components.MuiDataGrid.defaultProps.localeText}
      {...rest}
    />
  );
};

export default DataGrid;