import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { grey } from "@mui/material/colors";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {makeStyles} from '@mui/styles';

import ButtonWithLoader from "../../../../components/ButtonWithLoader";
import FormValidator from "../../../../components/Validators/FormValidator";
import TextFieldValidator from "../../../../components/Validators/TextFieldValidator";
import {SnackbarError, SnackbarFormattedError} from "../../../../helpers/snackbar";
import {userService} from "../store/services";

const useStyle = makeStyles((theme) => ({

  root: {
    '& .MuiContainer-root': {
      marginTop: 20,
      marginBottom: 20
    }
  },
  dialog:{
    zIndex: "999 !important"
  },
  cancel: {
    borderRadius: 8,
    fontSize: 16,
    height: 46,
    padding: '8px 20px',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  closeButton: {
    top: 10,
    right: 15,
    cursor: 'pointer',
    position: 'absolute',
    fontSize: 36,
    color: grey[500],
    [theme.breakpoints.down('xs')]: {
      top: 10,
      right: 20,
      fontSize: 40,
    },
  },
}));

const AddUserProNotesModal = ({user, dialogOpen, setDialogOpen, onChange}) => {

  const classes = useStyle();
  const {t} = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    consultationDate: Date.now(),
    nextConsultationDate: null,
    content1: "",
    content2: "",
    content3: "",
    content4: "",
    content5: "",
    content6: "",
  });

  const handleSubmit = () => {

    setFormSubmitted(true);
    if (data.consultationDate === null || data.content1 === "") {
      SnackbarError(t('errorMessageRequiredFields'));
      return false;
    }
    setLoading(true);
    userService.addUserProNote(user.id, data).then(() => {
      setLoading(false);
      setDialogOpen(false);
      window.location.reload();
    })
      .catch(e => {
        SnackbarFormattedError(e.error);
        setLoading(false);
      });
  };

  const handleChange = (event) => {
    setData({...data,
      [event.target.name]: event.target.value
    });
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <Dialog disableScrollLock onClose={handleClose} open={dialogOpen || false} fullScreen={fullScreen} className={classes.dialog}>
      <CloseIcon className={classes.closeButton} onClick={handleClose}/>
      <DialogTitle id="responsive-dialog-title">
        Ajouter un rapport de visite
      </DialogTitle>
      <DialogContent>
        <Card elevation={0} className={classes.root}>
          <Container>
            <Grid container spacing={2}>
              <Grid item lg={12}>
                <FormValidator
                  onSubmit={handleSubmit}
                  onError={errors => console.log(errors)}
                  noValidate
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextFieldValidator
                        fullWidth
                        name="consultationDate"
                        label={"Date de la visite"}
                        type={"date"}
                        required
                        value={data?.consultationDate}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldValidator
                        fullWidth
                        name="content1"
                        required
                        label={"Sujets abordés"}
                        multiline
                        value={data?.content1}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldValidator
                        fullWidth
                        name="content2"
                        multiline
                        label={"Réactions et commentaires"}
                        value={data?.content2}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldValidator
                        fullWidth
                        name="nextConsultationDate"
                        label={"Date de la prochaine visite"}
                        type={"date"}
                        value={data?.nextConsultationDate ?? Date.now()}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldValidator
                        fullWidth
                        name="content3"
                        multiline
                        label={"Objectif de la visite"}
                        value={data?.content3}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldValidator
                        fullWidth
                        name="content4"
                        multiline
                        label={"Actions à mener avant la visite"}
                        value={data?.content4}
                        onChange={handleChange}
                      />
                    </Grid>
                    {/*<Grid item xs={12}>
                      <TextFieldValidator
                        fullWidth
                        name="content5"
                        label={"content5"}
                        value={data?.content5}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldValidator
                        fullWidth
                        name="content6"
                        label={"content6"}
                        value={data?.content6}
                        onChange={handleChange}
                      />
                    </Grid>*/}
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item>
                          <Button
                            type="button"
                            fullWidth
                            variant="outlined"
                            color="primary"
                            className={classes.cancel}
                            onClick={handleClose}
                          >
                            <Trans i18nKey={"cancelButton"}>
                              Cancel
                            </Trans>
                          </Button>
                        </Grid>
                        <Grid item>
                          <ButtonWithLoader
                            type="submit"
                            isLoading={loading}
                            variant="contained"
                            color={"secondary"}
                          >
                            Ajouter le rapport
                          </ButtonWithLoader>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </FormValidator>
              </Grid>
            </Grid>
          </Container>
        </Card>
      </DialogContent>
    </Dialog>
  );
};
export default AddUserProNotesModal;
