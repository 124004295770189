import React, {useEffect} from "react";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import {useTranslation} from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {Box, Chip, Stack} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import theme from "../../../../assets/styles/theme/theme";
import LazyLoadImage from "../../../../components/LazyLoad/LazyLoadImage";
import {Link} from "../../../../helpers/multilingual";
import {getProductPriceWithTaxes, NRDID} from "../../../../helpers/utils";
import {shopService} from "../../../Admin/Shop/store/services";
import {isPro, userSelector} from "../../../Authentication/store/selectors";
import ItemPrice from "../components/ItemPrice";
import {getFormalizedName} from "../helpers/helper";
import {shopActions} from "../store/actions";
import {
  sessionSelector,
  shopProductRequestAnsweredEmptySelector,
  shopProductSelector
} from "../store/selectors";

import AddToCart from "./components/AddToCart";
import TabPanelContent from "./components/TabPanelContent";

const ProductPage = () => {

  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const userIsPro = useSelector(isPro);
  const {t, i18n} = useTranslation();
  const {productId} = useParams();
  const session = useSelector(sessionSelector);
  const product = useSelector(shopProductSelector);
  const shopProductRequestAnsweredEmpty = useSelector(shopProductRequestAnsweredEmptySelector);
  const [mask, setMask] = React.useState(1);
  const history = useHistory();
  const [fromInternalNavigation, setFromInternalNavigation] = React.useState(true);
  const [pdfs, setPdfs] = React.useState([]);

  //active campaign
  //useEffect(() => {
  //  const queryString = window.location.search;
  //
  //  function getQueryParam(queryString, param) {
  //    const queryParams = new URLSearchParams(queryString);
  //    return queryParams.get(param);
  //  }
  //
  //  const utm_source = getQueryParam(queryString, "utm_source");
  //  const utm_campaign = getQueryParam(queryString, "utm_campaign");
  //
  //  if(utm_source && utm_campaign) {
  //    dispatch(shopActions.setActiveCampaign({utm_source, utm_campaign}));
  //  }
  //}, []);

  useEffect(() => {
    setMask(maskRand());
    dispatch(shopActions.closeCartModal());

    if(typeof window !== 'undefined') {
      if (sessionStorage.getItem('fromInternalNavigation') === 'true') {
        setFromInternalNavigation(true);
      } else {
        setFromInternalNavigation(false);
      }
    }
  }, [typeof window]);

  useEffect(() => {
    if(product) {
      if(!product?.acceptSEO && !fromInternalNavigation) {
        //dispatch(shopActions.setFromInternalNavigation(false));
        history.push('/shop');
      }
    }

  }, [product, fromInternalNavigation]);

  useEffect(() => {
    //if product is requested but answer is {} ( because not connected or not found or anything else...) redirect to shop
    if(shopProductRequestAnsweredEmpty) {
      dispatch(shopActions.setShopProductRequestAnsweredEmpty(false));
      history.push('/shop');
    }
  }, [shopProductRequestAnsweredEmpty]);

  useEffect(() => {
    if(!product || product.id !== productId || product.detail.lang !== i18n.language) {
      dispatch(shopActions.getProductRequest(i18n.language, (!!session && session.country) || 'LU' , productId));
    }
  }, [i18n.language, productId]);

  useEffect(() => {
    if(pdfs.length === 0 && userIsPro) {
      shopService.getProductPdf(productId).then(pdfs => {
        setPdfs(pdfs);
      });
    }
  }, []);

  useEffect(() => {
    if(product) {
      const priceWithDiscount = getProductPriceWithTaxes(product, user, user?.shippingAddress, 1, true);
      const priceWithoutDiscount = getProductPriceWithTaxes(product, user, user?.shippingAddress, 1, false);
      const discount = (priceWithoutDiscount - priceWithDiscount).toFixed(2);

      ReactGA.event("view_item", {
        user_id: user?.id,
        currency: "EUR",
        value: priceWithDiscount,
        items: [
          {
            item_id: product.id,
            item_name: product.reference,
            discount: discount,
            index: 0,
            item_brand: product.brand.name,
            ...getCategoriesObject(),
            price: priceWithDiscount,
            quantity: 1
          }
        ]
      });
    }
  }, [product]);

  function getCategoriesObject() {
    const categories = {};
    product.categories.map((c, i) => {
      categories[`item_category${i ? i+1 : ''}`] = c.name;
    });
    return categories;
  }

  function maskRand(max = 7) {
    return Math.floor(Math.random() * max + 1);
  }

  if(!product) {
    return (
      <Box sx={{
        paddingBottom: 40,
        paddingTop: '11vw'
      }}>
        <Container fixed maxWidth="lg">
          <Button component={Link} to="/shop" variant="contained" color="primary" sx={{
            borderRadius: '24px',
            color: '#fff',
            fontSize: '1rem',
            fontFamily: 'Montserrat',
            height: '48px',
            margin: 'auto',
            textTransform: 'capitalize',
            '&:hover': {
              backgroundColor: '#fff',
              color: theme.palette.primary.main,
            },
          }}>
            {t('backToShop')}
          </Button>
        </Container>
      </Box>
    );
  }

  return (
    <Box sx={{
      paddingBottom: 40,
      paddingTop: '11vw'
    }}>
      <Helmet>
        <title>{product.reference}</title>
        <meta property="og:title" content={product?.reference}/>
        <meta property="og:type" content="product"/>
        <meta property="og:image" content={product?.image[800]}/>
        <meta property="og:url" content={`https://www.nutri-logics.com/shop/product/${getFormalizedName(product.reference)}/${product.id}`}/>
        <meta property="og:description" content={product.detail.metaDescription}/>
        <meta name="description" content={product.detail.metaDescription}/>
        <meta property="product:brand" content={getFormalizedName(product.brand.name)}/>
        <meta property="product:retailer_item_id" content={product.id} />
        <meta property="product:price:currency" content="EUR" />
        <meta property="product:price:amount" content={`${getProductPriceWithTaxes(product, null, null, 1, false)}`} />
        <meta property="product:condition" content="new" />
        <meta property="product:availability" content="in stock" />
        <link rel="preload" as="image" href={product.image[800]} />
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "Product",
            "id": "${product.id}",
            "name": "${product.reference}",
            "description": "${product.detail.metaDescription}",
            "image": "${product.image[800]}",
            "brand": {
              "@type": "Brand",
              "name": "${getFormalizedName(product.brand.name)}"
            },
            "offers": {
              "@type": "Offer",
              "url": "https://www.nutri-logics.com/shop/product/${getFormalizedName(product.reference)}/${product.id}",
              "priceCurrency": "EUR",
              "price": "${getProductPriceWithTaxes(product, null, null, 1, false)}",
                "availability": "https://schema.org/${product.outOfStock ? 'InStock' : 'OutOfStock'}",
            }
          }
        `}</script>
      </Helmet>
      <Container fixed maxWidth="lg">
        <Grid container spacing={1} sx={{mb: 2}}>
          <Grid item key={'categories'}>
            <Chip
              label={t('categories')}
              variant='contained'
              size="small"
              sx={{borderColor: '#3C515E', color: '#3C515E'}}
            />
          </Grid>
          {product.categories.length > 0 && product.categories.map(category => (
            <Grid item key={category.id}>
              <Chip
                component={Link}
                to={`/shop/category/${getFormalizedName(category.name)}/${category.id}`}
                clickable
                avatar={<Avatar alt="Icon" src={category.image} />}
                label={category.name}
                variant='contained'
                size="small"
                sx={{borderColor: '#3C515E', color: '#3C515E'}}
              />
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={2} justifyContent={'space-between'}>
          <Grid item xs={12} sm={4} md={6} lg={6} xl={6} xxl={6}>
            <Card
              sx={{
                boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.1)',
                borderRadius: '12px',
                maxWidth: {xs: '92vw', sm: '100%'},
                maxHeight: {xs: '92vw', sm: 'auto', md: 'auto'},
                position: 'relative',
              }}>
              <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                maskRepeat: 'no-repeat',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% !important',
                maskSize: '100%',
                maskImage: `url(${process.env.REACT_APP_API_RESOURCES_URL}/images/products/mask/Background-${mask}.svg)`,
                background: product.productBrandId === NRDID ? `url(${process.env.REACT_APP_API_RESOURCES_URL}/images/products/background/${product.background})` : product.backgroundColor,
              }}/>
              <LazyLoadImage
                width={600}
                height={600}
                src={product.image[800]}
                alt={product.reference}
                sx={{
                  position: 'relative',
                  top: 0,
                  left: 0,
                  '& img': {
                    maxWidth: '100%',
                    width: '100%',
                    height: 'auto'
                  }
                }}
                skeleton={
                  <img
                    width={600}
                    height={600}
                    src={product.image[200]}
                    alt={product.reference}
                  />
                }
              />

            </Card>
          </Grid>

          <Grid item xs={12} sm={8} md={6} lg={5} xl={5} xxl={5}
            sx={{
              width: '100%',
            }}>
            <Card sx={{
              boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.1)',
              borderRadius: '12px',
              padding: 2,
              position: {xs: 'inherit', sm: 'inherit', md: 'sticky', lg: 'sticky', xl: 'sticky', xxl: 'sticky'},
              top: '80px'
            }}>
              <Typography
                component="h2"
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: '2rem',
                }}>
                {product.reference}
              </Typography>
              <Typography dangerouslySetInnerHTML={{__html: product.detail.shortDescription}}
                sx={{
                  mb: 4,
                  color: '#7C909B',
                  fontFamily: 'Open sans',
                }}/>
              <Divider sx={{
                borderStyle: 'dashed',
                borderColor: '#7C909B',
                mt: 2
              }}/>
              <Stack
                direction={"row"}
                gap={2}
                justifyContent={"flex-end"}
                sx={{
                  pt: 2,
                  pb: 2,
                }}>
                {!!session && (
                  <Stack direction={"row"} gap={2} justifyContent={"flex-end"}>
                    <ItemPrice
                      item={product}
                      component={"span"}
                      session={session}
                      withDiscount
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: {xs: '1rem', sm: '1.2rem'},
                      }}
                      discountSx={{
                        position: 'relative',
                        bottom: '40px',
                        right: 'unset',
                        left: {xs: '145px', sm: '164px', md: '164px', lg: '164px', xl: '164px', xxl: '164px'},
                      }}
                    />
                    <ItemPrice
                      item={product}
                      component={"span"}
                      session={session}
                      total
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: {xs: '1rem', sm: '1.2rem'},
                      }}
                    />
                  </Stack>
                )}
              </Stack>

              <Divider sx={{
                borderStyle: 'dashed',
                borderColor: '#7C909B',
                mb: 2
              }}/>

              <AddToCart product={product} sx={{
                width: '100%',
                justifyContent: 'space-between',
                textTransform: 'uppercase',
              }}/>
              <Grid container spacing={2} justifyContent={'space-between'}>
                <Grid item xs={6}>
                  <Typography
                    dangerouslySetInnerHTML={{ __html: product?.detail?.shortIndications }}
                    sx={{
                      pt: 1,
                      '& p, & ul, & li, & span': {
                        color: '#7C909B !important',
                        fontFamily: 'Open Sans, sans-serif !important',
                      },
                      '& ul': {
                        listStyleType: 'none',
                        margin: 0,
                        padding: 0,
                      },
                      '& li': {
                        position: 'relative',
                        paddingLeft: '25px',
                      },
                      '& li::before': {
                        content: '"✔"',
                        color: theme.palette.primary.main,
                        fontSize: '16px',
                        position: 'absolute',
                        left: 0,
                        top: 0,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Stack direction={"row"} gap={2} justifyContent={"flex-end"} sx={{
                    p: 1,
                    height: 'auto'
                  }}>
                    <Box sx={{
                      borderLeft: '2px solid #7C909B',
                      px: 1
                    }}>
                      <Typography
                        sx={{
                          fontSize: '1.3rem',
                          fontFamily: 'Montserrat',
                          lineHeight: '1',
                          fontWeight: '600',
                          color: '#7C909B',
                        }}>
                        {product.quantity}
                      </Typography>
                      <Typography sx={{
                        fontSize: '0.7rem',
                        fontFamily: 'Montserrat',
                        color: '#7C909B',
                        textTransform: 'lowercase',
                      }}>
                        {product.unit}
                      </Typography>
                    </Box>
                    <Box sx={{
                      borderLeft: '2px solid #7C909B',
                      pl: 1,
                    }}>
                      <Typography sx={{
                        fontSize: '1.3rem',
                        fontFamily: 'Montserrat',
                        fontWeight: '600',
                        lineHeight: '1',
                        color: '#7C909B',
                      }}>
                      ℮ {product.netWeight}
                        <Typography
                          component={"span"}
                          sx={{
                            fontSize: '0.8rem',
                            fontFamily: 'Montserrat',
                            color: '#7C909B',
                          }}>
                        g
                        </Typography>
                      </Typography>

                      <Typography sx={{
                        fontSize: '0.65rem',
                        fontFamily: 'Montserrat',
                        color: '#7C909B',
                      }}>
                        {t('weightLabel')}
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Card>

          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <TabPanelContent product={product} session={session} isPro={user && user?.isPro} pdfs={pdfs}/>

            {/*product.reviewsRating && (
                <Typography align={"center"}>
                  <Rating name="disabled" value={parseFloat(product.reviewsRating)} precision={0.25} disabled />
                  <Typography component={"span"}>{product.reviewsCount} {t('reviewLabel', {count:product.reviewsCount})}</Typography>
                </Typography>
              )*/}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ProductPage;
