export const GET_CUSTOMERS_REQUEST    = 'CUSTOMERS/GET_CUSTOMERS_REQUEST';
export const GET_CUSTOMERS_ERROR      = 'CUSTOMERS/GET_CUSTOMERS_ERROR';
export const GET_CUSTOMERS_SUCCESS    = 'CUSTOMERS/GET_CUSTOMERS_SUCCESS';

export const GET_CUSTOMER_REQUEST    = 'CUSTOMERS/GET_CUSTOMER_REQUEST';
export const GET_CUSTOMER_ERROR      = 'CUSTOMERS/GET_CUSTOMER_ERROR';
export const GET_CUSTOMER_SUCCESS    = 'CUSTOMERS/GET_CUSTOMER_SUCCESS';

export const GET_CUSTOMER_ORDERS_REQUEST    = 'CUSTOMERS/GET_CUSTOMER_ORDERS_REQUEST';
export const GET_CUSTOMER_ORDERS_SUCCESS    = 'CUSTOMERS/GET_CUSTOMER_ORDERS_SUCCESS';
export const GET_CUSTOMER_ORDERS_ERROR      = 'CUSTOMERS/GET_CUSTOMER_ORDERS_ERROR';

export const GET_CUSTOMER_ANAMNESES_REQUEST = 'CUSTOMERS/GET_CUSTOMER_ANAMNESES_REQUEST';
export const GET_CUSTOMER_ANAMNESES_SUCCESS = 'CUSTOMERS/GET_CUSTOMER_ANAMNESES_SUCCESS';
export const GET_CUSTOMER_ANAMNESES_ERROR   = 'CUSTOMERS/GET_CUSTOMER_ANAMNESES_ERROR';

export const ADD_CUSTOMER_REQUEST    = 'CUSTOMERS/ADD_CUSTOMER_REQUEST';
export const ADD_CUSTOMER_ERROR      = 'CUSTOMERS/ADD_CUSTOMER_ERROR';
export const ADD_CUSTOMER_SUCCESS    = 'CUSTOMERS/ADD_CUSTOMER_SUCCESS';

export const ADD_CUSTOMER_ORDER_REQUEST    = 'CUSTOMERS/ADD_CUSTOMER_ORDER_REQUEST';
export const ADD_CUSTOMER_ORDER_ERROR      = 'CUSTOMERS/ADD_CUSTOMER_ORDER_ERROR';
export const ADD_CUSTOMER_ORDER_SUCCESS    = 'CUSTOMERS/ADD_CUSTOMER_ORDER_SUCCESS';

export const ADD_CUSTOMER_PRESCRIPTION_REQUEST    = 'CUSTOMERS/ADD_CUSTOMER_PRESCRIPTION_REQUEST';
export const ADD_CUSTOMER_PRESCRIPTION_ERROR      = 'CUSTOMERS/ADD_CUSTOMER_PRESCRIPTION_ERROR';
export const ADD_CUSTOMER_PRESCRIPTION_SUCCESS    = 'CUSTOMERS/ADD_CUSTOMER_PRESCRIPTION_SUCCESS';

export const UPDATE_CUSTOMER_REQUEST    = 'CUSTOMERS/UPDATE_CUSTOMER_REQUEST';
export const UPDATE_CUSTOMER_ERROR      = 'CUSTOMERS/UPDATE_CUSTOMER_ERROR';
export const UPDATE_CUSTOMER_SUCCESS    = 'CUSTOMERS/UPDATE_CUSTOMER_SUCCESS';

export const UPDATE_CUSTOMER_SHIPPING_ADDRESS_REQUEST  = 'CUSTOMERS/UPDATE_CUSTOMER_SHIPPING_ADDRESS_REQUEST';
export const UPDATE_CUSTOMER_SHIPPING_ADDRESS_SUCCESS  = 'CUSTOMERS/UPDATE_CUSTOMER_SHIPPING_ADDRESS_SUCCESS';
export const UPDATE_CUSTOMER_SHIPPING_ADDRESS_ERROR    = 'CUSTOMERS/UPDATE_CUSTOMER_SHIPPING_ADDRESS_ERROR';

export const GET_CUSTOMER_ANAMNESIS_REQUEST  = 'CUSTOMERS/GET_CUSTOMER_ANAMNESIS_REQUEST';
export const GET_CUSTOMER_ANAMNESIS_SUCCESS  = 'CUSTOMERS/GET_CUSTOMER_ANAMNESIS_SUCCESS';
export const GET_CUSTOMER_ANAMNESIS_ERROR    = 'CUSTOMERS/GET_CUSTOMER_ANAMNESIS_ERROR';

export const ADD_CUSTOMER_ANAMNESIS_NOTE_REQUEST    = 'CUSTOMERS/ADD_CUSTOMER_ANAMNESIS_NOTE_REQUEST';
export const ADD_CUSTOMER_ANAMNESIS_NOTE_SUCCESS    = 'CUSTOMERS/ADD_CUSTOMER_ANAMNESIS_NOTE_SUCCESS';
export const ADD_CUSTOMER_ANAMNESIS_NOTE_ERROR      = 'CUSTOMERS/ADD_CUSTOMER_ANAMNESIS_NOTE_ERROR';

export const DELETE_CUSTOMER_ANAMNESIS_NOTE_REQUEST    = 'CUSTOMERS/DELETE_CUSTOMER_ANAMNESIS_NOTE_REQUEST';
export const DELETE_CUSTOMER_ANAMNESIS_NOTE_SUCCESS    = 'CUSTOMERS/DELETE_CUSTOMER_ANAMNESIS_NOTE_SUCCESS';
export const DELETE_CUSTOMER_ANAMNESIS_NOTE_ERROR      = 'CUSTOMERS/DELETE_CUSTOMER_ANAMNESIS_NOTE_ERROR';

export const REQUEST_SUPPORT_REQUEST    = 'CUSTOMERS/REQUEST_SUPPORT_REQUEST';
export const REQUEST_SUPPORT_SUCCESS    = 'CUSTOMERS/REQUEST_SUPPORT_SUCCESS';
export const REQUEST_SUPPORT_ERROR      = 'CUSTOMERS/REQUEST_SUPPORT_ERROR';

export const GET_CUSTOMER_REDIRECTIONS_REQUEST  = 'CUSTOMERS/GET_CUSTOMER_REDIRECTIONS_REQUEST';
export const GET_CUSTOMER_REDIRECTIONS_SUCCESS  = 'CUSTOMERS/GET_CUSTOMER_REDIRECTIONS_SUCCESS';
export const GET_CUSTOMER_REDIRECTIONS_ERROR    = 'CUSTOMERS/GET_CUSTOMER_REDIRECTIONS_ERROR';