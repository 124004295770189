import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import {AppBar, Box, Stack, Toolbar} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import {userSelector} from "../../../containers/Authentication/store/selectors.js";
import { walletActions } from "../../../containers/Customer/Wallet/store/actions";
import { walletSelector } from "../../../containers/Customer/Wallet/store/selectors";
import { Link } from "../../../helpers/multilingual";

import MenuDrawer from "./MenuDrawer";

const drawerWidth = 240;

export default function Header() {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const dispatch = useDispatch();
  const wallet = useSelector(walletSelector());
  const user = useSelector(userSelector);

  useEffect(() => {
    if(!wallet.isSet) {
      dispatch(walletActions.getWalletRequest());
    }
  }, []);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'block', md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Stack direction={"row"} gap={2} alignItems={"center"}>
            <IconButton component={Link} to={`/`} sx={{color: "#FFFFFF"}}>
              <ExitToAppIcon/>
            </IconButton>
            <Typography sx={{color: "#FFFFFF"}}>
              Mon code pro : {user.proCode}
            </Typography>
          </Stack>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          <MenuDrawer setMobileOpen={setMobileOpen}/>
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          <MenuDrawer/>
        </Drawer>
      </Box>
    </>
  );
}

/*
<AppBar elevation={1} position="sticky" className={classes.root}>
      <Toolbar className={classes.appBar}>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <Grid container alignItems="center">
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
                <Hidden smDown implementation={"css"}>
                  <Link className={classes.logo} to="/pro">
                    <img src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/logo/logo-pro.svg`} alt=""/>
                  </Link>
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={10}>
            <Grid container alignItems="center" justify="flex-end" className={classes.buttonIcons} spacing={3}>

<Grid item xs={4}>
  <Button
      variant="contained"
      color="primary"
      startIcon={<ExitToAppIcon/>}
      sx={{color:"#FFFFFF",display:'flex'}}
      onClick={() => history.push('/')}
  >
    Retourner au site
  </Button>
</Grid>
<Grid item xs={4}>
  <Typography
      color={"primary"}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        fontSize: 18,
        fontWeight: 500,
      }}
  >
    Mon code pro : {user.proCode}
  </Typography>
</Grid>
</Grid>
</Grid>
</Grid>
<SearchDrawer searchDrawerOpen={searchDrawerOpen} setSearchDrawerOpen={setSearchDrawerOpen}/>
</Toolbar>
</AppBar>
 */
