import React, {useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import TuneIcon from '@mui/icons-material/Tune';
import {Box, Hidden, NoSsr, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";

import DotsLoader from "../../../components/DotsLoader";
import {Link} from "../../../helpers/multilingual";
import {SnackbarFormattedError} from "../../../helpers/snackbar.js";

import Filters from "./components/Filters";
import Item from "./components/Item";
import ShopTitle from "./components/ShopTitle";
import ShopToolbar from "./components/ShopToolbar";
import {shopActions} from "./store/actions";
import {
  sessionSelector
} from "./store/selectors";
import {shopService} from "./store/services.js";

const removeAccents = (str) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

const Search = () => {

  const dispatch = useDispatch();
  const {i18n} = useTranslation();
  const {search} = useParams();
  const [view, setView] = useState('grid');
  const [open, setOpen] = useState(false);
  const isDownSm = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const session = useSelector(sessionSelector);
  const [products, setProducts] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [sectionName, setSectionName] = useState('category');

  useEffect(() => {
    (async () => {
      dispatch(shopActions.getCategoriesRequest(i18n.language));
      dispatch(shopActions.getThemesRequest(i18n.language));
      dispatch(shopActions.getBrandsRequest());
    })();
  }, [i18n.language]);

  useEffect(() => {
    getProducts();
  }, [i18n.language, search]);

  const getProducts = () => {
    if (search.length >= 2) {
      //setSearchLoading(true);
      shopService.searchProducts(i18n.language, "FR", search)
        .then((products) => {
          const searchNormalized = removeAccents(search.toLowerCase());
          const privilegedBrandId = "083632d8-fa53-4a21-8a95-c46b3a4c4e88";

          const getScore = (product) => {
            let score = 0;
            // Retrieve and normalize product fields
            const ref = removeAccents(product.reference?.toLowerCase() || "");
            const st = removeAccents(product.searchText?.toLowerCase() || "");
            const comp = removeAccents(product.detail?.composition?.toLowerCase() || "");

            // --- Vérifie la marque privilégiée ---
            if (product.brand?.id === privilegedBrandId) {
              // Bonus "raisonnable" pour la marque
              score += 30;
            }

            // --- Vérifie la référence ---
            if (ref.startsWith(searchNormalized)) {
              score += 140;
            }
            if (ref.includes(searchNormalized)) {
              score += 80;
            }

            // --- Vérifie searchText ---
            if (st.includes(searchNormalized)) {
              score += 50;
            }

            // --- Vérifie la composition ---
            if (comp.includes(searchNormalized)) {
              score += 20;
            }
            return score;
          };

          // On trie par score décroissant
          products.sort((a, b) => getScore(b) - getScore(a));

          setProducts(products);
          setSearchLoading(false);
        })
        .catch(e => {
          console.log(e);
          SnackbarFormattedError(e.response.data.error);
          setSearchLoading(false);
        });
    }
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <NoSsr>
      <Box sx={{
        paddingBottom: 40,
        paddingTop: {xs: '0', sm: '11vw'},
      }}
      >
        <ShopTitle/>
        {isDownSm && (
          <Box
            sx={{
              padding: '8px 16px',
              boxShadow: '0px 12px 23px #00000021',
              position: 'sticky',
              top: 0,
              left: 0,
              zIndex: 1200,
              background: '#fff',
            }}
          >
            <Stack direction={"row"} gap={2} justifyContent={"space-between"}>
              <Button
                key={'allCategory'}
                variant={"outlined"}
                component={Link}
                to={`/shop`}
                sx={{
                  borderRadius: '12px',
                  height: '24px',
                  width: 'auto',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  border:'1px solid #FFFFFF',
                  backgroundColor:'#EEF1F2',
                  color:'#3C515E'
                }}
              >
                <Trans i18nKey={"allProducts"}>All products</Trans>
              </Button>
              <Button
                onClick={toggleDrawer}
                startIcon={<TuneIcon/>}
                sx={{
                  borderRadius: '30px',
                  height: '28px',
                  padding: '6px 14px',
                  width: 'auto',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  border: '1px solid #FFFFFF',
                  backgroundColor: '#EEF1F2',
                  color: '#3C515E'
                }}
              >
                {'Filtres'}
              </Button>
            </Stack>
          </Box>
        )}

        <Container fixed maxWidth={'xxl'}>
          <Hidden smDown implementation={"css"}>
            <ShopToolbar sectionName={sectionName} setSectionName={setSectionName} numbers={products?.length || 0} setView={setView} displaySortingButton={false}/>
          </Hidden>
          <Grid container spacing={2}>
            <Grid item sm={3}>
              <Filters sectionName={sectionName} open={open} onToggleDrawer={toggleDrawer}/>
            </Grid>
            <Grid item sm={9}>
              <Grid container spacing={2}>
                {products && products?.length > 0 && (
                  products?.map((product) => (
                    <Grid
                      item xs={12}
                      sm={view === 'grid' ? 6 : 12}
                      md={view === 'grid' ? 6 : 12}
                      lg={view === 'grid' ? 4 : 12}
                      xl={view === 'grid' ? 4 : 12}
                      xxl={view === 'grid' ? 3 : 12}
                      key={product.id}
                    >
                      <Item product={product} session={session}/>
                    </Grid>
                  ))
                )}
                <Grid item xs={12}>
                  {searchLoading  && <DotsLoader/>}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </NoSsr>
  );
};

export default Search;

/*
import React, { useState, useEffect, useRef } from 'react';

function ProductList() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef(null);

  useEffect(() => {
    // Initial fetch of products
    fetchProducts(currentPage);

    // Set up IntersectionObserver
    observer.current = new IntersectionObserver(
      (entries) => {
        const firstEntry = entries[0];
        if (firstEntry.isIntersecting && hasMore) {
          // Fetch more products
          fetchProducts(currentPage + 1);
        }
      },
      { threshold: 1 }
    );

    // Clean up IntersectionObserver
    return () => {
      observer.current.disconnect();
    };
  }, []);

  const fetchProducts = async (page) => {
    setLoading(true);
    const newProducts = await fetchingFunction(page);

    if (newProducts.length === 0) {
      setHasMore(false);
    } else {
      setProducts((prevProducts) => [...prevProducts, ...newProducts]);
      setCurrentPage(page);
    }

    setLoading(false);
  };

  return (
    <div>
      {products.map((product, index) => (
        <div key={index}>
          <h3>{product.name}</h3>
          <p>{product.description}</p>
        </div>
      ))}
      {loading && <p>Loading...</p>}
      {!loading && hasMore && (
        <div ref={observer}>
          <p>Scroll down to load more products</p>
        </div>
      )}
      {!loading && !hasMore && (
        <p>End of products list</p>
      )}
    </div>
  );
}

export default ProductList;

 */