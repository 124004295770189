import React, { useEffect, useState } from "react";
import {useDispatch} from "react-redux";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { withStyles } from '@mui/styles';
import i18next from "i18next";

import {SnackbarError} from "../../../../../helpers/snackbar.js";
import useMoment from "../../../../../helpers/useMoment";
import {shopActions} from "../../../Shop/store/actions.js";
import { shopService } from "../../../Shop/store/services";

import PrescriptionDetails from "./components/PrescriptionDetails.js";
import PrescriptionStatus from "./components/PrescriptionStatus.js";

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const Prescriptions = () => {

  const moment = useMoment();
  const [prescriptions, setPrescriptions] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    shopService.getPrescriptions(i18next.language).then((fetchedPrescriptions) => {
      const sortedPrescriptions = fetchedPrescriptions.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setPrescriptions(sortedPrescriptions);
    });
  }, []);

  const removePrescription = (id) => {
    shopService.removePrescription(id).then(() => {
      dispatch(shopActions.getPrescriptionsCountRequest());
      setPrescriptions(prescriptions.filter(prescription => prescription.id !== id));
    }).catch((e) => {
      SnackbarError("Une erreur s'est produite");
      console.log(e);
    });
  };

  return (
    <>
      {prescriptions && prescriptions.length > 0
        ? prescriptions?.map(prescription => (
          <Accordion key={prescription.id}>
            <AccordionSummary aria-controls={`panel1d-content-${prescription.id}`} id={`panel1d-header-${prescription.id}`}>
              <Grid container justify={"space-between"} alignItems={"center"}>
                <Grid item>
                  <Typography sx={{
                    fontFamily: 'Open sans',
                    fontSize: '0.9rem !important',
                    '& b': {
                      fontFamily: 'Montserrat',
                    }
                  }}>
                    <b>{moment(prescription.createdAt).format('LL')}</b> - <PrescriptionStatus prescription={prescription}/>
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <PrescriptionDetails prescription={prescription} removePrescription={removePrescription}/>
            </AccordionDetails>
          </Accordion>
        ))
        : <Typography sx={{
          fontFamily: 'Open sans',
          color: 'primary.light',
          fontSize: '0.9rem !important'
        }}>
            Vous n'avez pas encore de prescription.
        </Typography>
      }
    </>
  );
};
export default Prescriptions;
