import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { grey } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import {userService} from "../store/services";

import AddUserProNotesModal from "./AddUserProNotesModal.js";
import ProNotes from "./ProNotes.js";

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
      borderBottom: `1px solid ${grey[300]}`,
      borderRadius: 0
    },
    '& .item': {
      padding: '0px 4px',
    },
    '& .order-options': {
      '& .MuiButton-root': {
        marginRight: 8
      }
    }
  },
}));

const UserProNotesCard = ({user}) => {

  const classes = useStyles();
  //const [maximized, setMaximized] = useState(false);
  const [proNotes, setProNotes] = useState([]);
  //const [proNote, setProNote] = useState([]);
  //const [proNoteModalOpen, setProNoteModalOpen] = useState(false);
  const [addProNoteModalOpen, setAddProNoteModalOpen] = useState(false);

  useEffect(() => {

    loadProNotes();
  }, [user]);

  const loadProNotes = () => {
    userService.findUserProNotes(user.id).then(proNotes => {
      setProNotes(proNotes);
    });
  };

  //const handleProNoteModalOpen = () => {
  //  setProNoteModalOpen(true);
  //};
  //const handleProNoteModalClose = () => {
  //  setProNoteModalOpen(false);
  //};

  const handleAddProNoteModalOpen = () => {
    setAddProNoteModalOpen(true);
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container justify={"space-between"} alignItems={"center"} spacing={2}>
              <Grid item xs={12}>
                <Grid container justify={"space-between"} spacing={2}>
                  <Grid item xs={6}>
                    <Typography component="h3" variant={"h6"} gutterBottom>
                                            Rapports de visites :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align={"right"} className={"order-options"}>
                      <Button variant="outlined" onClick={handleAddProNoteModalOpen} size={"large"} color={"primary"} disableElevation>
                                                Créer un rapport de visite
                      </Button>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ProNotes proNotes={proNotes}/>
                  </Grid>
                </Grid>
              </Grid>
              <AddUserProNotesModal user={user} setDialogOpen={setAddProNoteModalOpen} dialogOpen={addProNoteModalOpen}/>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default UserProNotesCard;
