import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";

import {getProductPriceWithTaxes} from "../../../../../helpers/utils.js";

const ItemPrice = ({item, quantity= 1, user, withDiscount = false, total = false, variant, sx, discountSx}) => {

  const [price, setPrice] = useState(0);
  const [priceWithDiscount, setPriceWithDiscount] = useState(0);
  const {discountPercent} = item;


  useEffect(() => {
    setPriceWithDiscount(getProductPriceWithTaxes(item, user, user?.shippingAddress, quantity, false));
    setPrice(getProductPriceWithTaxes(item, user, user?.shippingAddress, quantity, true));
  }, [quantity]);

  if(!price) {
    return null;
  }

  return (
    <React.Fragment key={user?.userDiscountId}>
      <Typography
        variant={variant}
        sx={{
          color: '#7C909B',
          fontFamily: 'Open sans',
          fontWeight: 'bold',
          fontSize: {xs: '0.875rem', sm: '1rem'},
          textAlign: 'right',
          ...sx
        }}
      >

        {(discountPercent !== 0 && withDiscount) && (
          <span style={{textDecoration: 'line-through'}}>
            {priceWithDiscount + '€'}
          </span>
        )}
        {total && (
          <span>
            {price + '€'}
          </span>
        )}
      </Typography>
    </React.Fragment>
  );
};
export default ItemPrice;
