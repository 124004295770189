import React from "react";
import { Trans } from "react-i18next";
import Typography from "@mui/material/Typography";

const PrescriptionStatus = ({prescription}) => {

  switch (prescription?.status) {
    case 'sent':
      return (
        <Typography component={"i"}><Trans i18nKey={"prescriptionSentStatus"}>Prescription not ordered</Trans></Typography>
      );
    case 'ordered':
      return (
        <Typography component={"i"}><Trans i18nKey={"prescriptionOrderedStatus"}>Prescription ordered</Trans></Typography>
      );
    case 'initialized':
      return (
        <Typography component={"i"}><Trans i18nKey={"prescriptionInitializedStatus"}>Prescription initialized</Trans></Typography>
      );
    case 'expired':
      return (
        <Typography component={"i"}><Trans i18nKey={"prescriptionExpiredStatus"}>Prescription expired</Trans></Typography>
      );
    default:
      return null;
  }

};
export default PrescriptionStatus;
