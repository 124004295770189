import React, { useEffect, useRef,useState } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";

const DEBOUNCE_DELAY = 300;

const GridToolbarQuickSearch = ({
  value = "",
  data,
  setRows,
  setSearchText,
  toolBarQuickSearchNestedObjects,
  toolBarQuickSearchTranslations
}) => {
  // Local state for the text field value
  const [searchInput, setSearchInput] = useState(value);

  // We'll store the timeout ID in a ref, so we can clear it on re-renders
  const debounceTimeout = useRef(null);

  /**
   * Replaces your original requestSearch call on each keystroke.
   * Instead, this effect triggers after `searchInput` changes,
   * waiting DEBOUNCE_DELAY ms before calling `requestSearch`.
   */
  useEffect(() => {
    // Clear any pending timeout so we don't run requestSearch multiple times
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set up the new timeout
    debounceTimeout.current = setTimeout(() => {
      requestSearch(searchInput);
    }, DEBOUNCE_DELAY);

    // Cleanup on unmount or next effect run
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [searchInput]);

  function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  function getValueFromNestedPath(obj, path) {
    return path.split('.').reduce((acc, key) => {
      if (acc && typeof acc === 'object') {
        return acc[key];
      }
      return undefined;
    }, obj);
  }

  /**
   * This function remains the same as your existing logic.
   * It's just called after a debounce delay, not on every keystroke.
   */
  const requestSearch = (searchValue) => {
    if (!data) return;

    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    let filteredRows = data;

    filteredRows = filteredRows.filter((row) => {
      // 1. Check all top-level fields
      const topLevelMatches = Object.keys(row).some((field) => {
        // If we have translations for a certain value, use it
        if (toolBarQuickSearchTranslations[row[field]?.toString()]) {
          return searchRegex.test(
            toolBarQuickSearchTranslations[row[field]?.toString()]?.toString() ?? ""
          );
        }
        return searchRegex.test(row[field]?.toString() ?? "");
      });

      // 2. Check nested paths if top-level didn't match
      const nestedMatches =
        toolBarQuickSearchNestedObjects?.some((nestedPath) => {
          const nestedValue = getValueFromNestedPath(row, nestedPath);
          if (toolBarQuickSearchTranslations[nestedValue?.toString()]) {
            return searchRegex.test(
              toolBarQuickSearchTranslations[nestedValue?.toString()]?.toString() ?? ""
            );
          }
          return searchRegex.test(nestedValue?.toString() ?? "");
        }) ?? false;

      // 3. Include row if either top-level *or* a nested path matches
      return topLevelMatches || nestedMatches;
    });

    setRows(filteredRows);
  };

  const handleClearSearch = () => {
    setSearchInput(""); // This triggers the effect to call requestSearch("") after the delay
  };

  return (
    <Box>
      <TextField
        fullWidth
        variant="standard"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        placeholder="Chercher…"
        InputProps={{
          startAdornment: <SearchIcon />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: searchInput ? 'visible' : 'hidden' }}
              onClick={handleClearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={(theme) => ({
          '& .MuiInputBase-input': {
            p: theme.spacing(1),
            '&::placeholder': {
              color: theme.palette.common.black
            },
            fontWeight: 700,
            lineHeight: 1.7142857142857142,
            fontSize: '0.8125rem'
          },
        })}
      />
    </Box>
  );
};

export default GridToolbarQuickSearch;
