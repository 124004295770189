import React from "react";
import {useDispatch, useSelector} from "react-redux";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import {IconButton} from "@mui/material";
import Badge from "@mui/material/Badge";

import {prescriptionCountSelector} from "../../../containers/Authentication/store/selectors.js";
import {shopActions} from "../../../containers/Customer/Shop/store/actions";

const UserMenu = () => {

  const dispatch = useDispatch();
  const prescriptionCount = useSelector(prescriptionCountSelector);

  const handleOpenAccountDrawer = () => {
    dispatch(shopActions.SetDrawersOpen("account", true));
  };

  return (
    <IconButton
      onClick={handleOpenAccountDrawer}
      variant={"text"}
    >
      <Badge
        color="error"
        badgeContent={
          prescriptionCount > 99
            ? '99+'
            : prescriptionCount > 0
              ? prescriptionCount
              : null
        }
      >
        <PersonOutlineIcon/>
      </Badge>
    </IconButton>
  );
};
export default UserMenu;
