import produce from "immer";

import Cookie from "../../../../helpers/cookie";
import * as authenticationActionsType from '../../../Authentication/store/constants';

import * as actionsType from './constants';
import { initialState } from "./initialState";

export const shopReducerKey = 'shop';

export default (state = initialState.shop, action) =>
  produce(state, draft => {
    switch (action.type) {
      case actionsType.GET_SHIPPING_ADDRESSES_REQUEST:
        draft.userShipping.addressesRequested = true;
        break;

      case actionsType.GET_SHIPPING_ADDRESSES_SUCCESS:
        draft.userShipping.addressesRequested = false;
        draft.userShipping.addresses = action.shippingAddresses;
        break;

      case actionsType.GET_SHIPPING_ADDRESSES_FAILED:
        draft.userShipping.addressesRequested = false;
        draft.userShipping.addresses = [];
        break;

      case actionsType.ADD_SHIPPING_ADDRESS_REQUEST:
        draft.userShipping.addAddressRequested = true;
        break;

      case actionsType.ADD_SHIPPING_ADDRESS_SUCCESS:
      //draft.userShipping.addresses = draft.userShipping.addresses.map(address => ({...address, byDefault: false}));
        draft.userShipping.addresses.unshift(action.shippingAddress);
        draft.userShipping.addAddressRequested = false;
        break;

      case actionsType.ADD_SHIPPING_ADDRESS_FAILED:
        draft.userShipping.addAddressRequested = false;
        break;

      case actionsType.UPDATE_SHIPPING_ADDRESS_REQUEST:
        draft.userShipping.updateAddressRequested = true;
        break;

      case actionsType.UPDATE_SHIPPING_ADDRESS_SUCCESS:
        draft.userShipping.updateAddressRequested = false;
        draft.cart.shipping.address = action.shippingAddress;
        Cookie.set('shipping-address', action.shippingAddress);
        break;

      case actionsType.UPDATE_SHIPPING_ADDRESS_FAILED:
        draft.userShipping.updateAddressRequested = false;
        break;

      case actionsType.SET_AS_DEFAULT_SHIPPING_ADDRESS_REQUEST:
        break;

      case actionsType.SET_AS_DEFAULT_SHIPPING_ADDRESS_SUCCESS:
        draft.userShipping.addresses = draft.userShipping.addresses.map(address => ({...address, byDefault: address.id === action.shippingAddress.id}));
        draft.cart.shipping.address = action.shippingAddress;
        Cookie.set('shipping-address', action.shippingAddress);
        draft.cart.shipping.method = null;
        Cookie.remove('shipping-method');
        draft.cart.shipping.pickupPoint = null;
        Cookie.remove('shipping-pickup-point');
        draft.checkout = null;
        break;

      case actionsType.SET_AS_DEFAULT_BILLING_ADDRESS_SUCCESS:
        draft.userShipping.addresses = draft.userShipping.addresses.map(address => ({...address, byDefaultBilling: address.id === action.billingAddress.id}));
        draft.cart.shipping.address = action.billingAddress;
        Cookie.set('billing-address', action.billingAddress);
        draft.cart.shipping.method = null;
        Cookie.remove('shipping-method');
        draft.cart.shipping.pickupPoint = null;
        Cookie.remove('shipping-pickup-point');
        draft.checkout = null;
        break;

      case actionsType.SET_AS_DEFAULT_SHIPPING_ADDRESS_FAILED:
        break;

      case actionsType.REMOVE_SHIPPING_ADDRESS_REQUEST:
        draft.userShipping.removeAddressRequested = true;
        break;

      case actionsType.REMOVE_SHIPPING_ADDRESS_SUCCESS:
        draft.userShipping.addresses = draft.userShipping.addresses.filter(address => address.id !== action.shippingAddress.id);
        draft.userShipping.removeAddressRequested = false;
        break;

      case actionsType.REMOVE_SHIPPING_ADDRESS_FAILED:
        draft.userShipping.removeAddressRequested = false;
        break;

      case actionsType.GET_PRODUCTS_REQUEST:
      case actionsType.GET_EVENTS_REQUEST:
        draft.productsRequested = true;
        break;

      case actionsType.GET_PRODUCTS_SUCCESS:
      case actionsType.GET_EVENTS_SUCCESS:
        draft.productsRequested = false;
        let {count} = action.products.pop();
        // eslint-disable-next-line no-case-declarations
        const productsKey = `${action.keyType}-${action.section}-${action.sectionId}-${action.country}-${action.lang}`;
        draft.products[productsKey] = draft.products[productsKey] ? draft.products[productsKey] : { data: [], endOfProducts: false, currentPage: 0 };
        const existingProducts = draft.products[productsKey].data;
        const newProducts = action.products.filter(newProduct => !existingProducts.some(existingProduct => existingProduct.id === newProduct.id));
        draft.products[productsKey].data = [...existingProducts, ...newProducts];
        if(count === draft.products[productsKey].data.length) {
          draft.products[productsKey].endOfProducts = true;
        }
        break;

      case actionsType.SET_CURRENT_PAGE:
        const key = `${action.keyType}-${action.section}-${action.sectionId}-${action.country}-${action.lang}`;
        draft.products[key] = draft.products[key] ? draft.products[key] : { data: [], endOfProducts: false, currentPage: 0 };
        draft.products[key].currentPage = action.offset;

        break;

      case actionsType.GET_PRODUCTS_FAIL:
      case actionsType.GET_EVENTS_FAIL:
        draft.productsRequested = false;
        break;

      case actionsType.GET_TOP_PRODUCTS_REQUEST:
        draft.productsRequested = true;
        break;

      case actionsType.GET_TOP_PRODUCTS_SUCCESS:
        draft.productsRequested = false;
        draft.topProducts = action.products;
        break;

      case actionsType.GET_TOP_PRODUCTS_FAIL:
        draft.productsRequested = false;
        break;

      case actionsType.GET_CATALOG_PRODUCTS_REQUEST:
        break;

      case actionsType.GET_CATALOG_PRODUCTS_SUCCESS:
        draft.catalog = action.products;
        break;

      case actionsType.GET_CATALOG_PRODUCTS_FAIL:
        draft.catalog = [];
        break;

      case actionsType.SEARCH_PRODUCTS_REQUEST:
        draft.search = action.search;
        break;

      case actionsType.SEARCH_PRODUCTS_SUCCESS:
        draft.searchProducts = action.products;
        break;

      case actionsType.SEARCH_PRODUCTS_FAIL:
        draft.searchProducts = [];
        draft.search = '';
        break;

      case actionsType.SEARCH_DRAWER_OPEN:
        draft.isSearchDrawerOpen = action.isOpen;
        break;

      case actionsType.GET_CATEGORIES_REQUEST:
        break;

      case actionsType.GET_CATEGORIES_SUCCESS:
        draft.categories[action.lang] = action.categories;
        break;

      case actionsType.GET_CATEGORIES_FAIL:
        draft.categories[action.lang] = [];
        break;

      case actionsType.GET_THEMES_REQUEST:
        break;

      case actionsType.GET_THEMES_SUCCESS:
        draft.themes[action.lang] = action.themes;
        break;

      case actionsType.GET_THEMES_FAIL:
        draft.themes[action.lang] = [];
        break;

      case actionsType.GET_BRANDS_REQUEST:
        break;

      case actionsType.GET_BRANDS_SUCCESS:
        draft.brands = action.brands;
        break;

      case actionsType.GET_BRANDS_FAIL:
        draft.brands = [];
        break;

      case actionsType.GET_CATEGORY_REQUEST:
        draft.categoryId = action.categoryId;
        break;

      case actionsType.GET_PRODUCT_REQUEST:
        draft.product = null;
        break;

      case actionsType.GET_PRODUCT_SUCCESS:
        draft.product = action.product;
        break;

      case actionsType.GET_PRODUCT_FAIL:
        draft.product = null;
        break;

      case actionsType.CART_OPEN_MODAL:
        draft.cart.isModalOpened = true;
        break;

      case actionsType.CART_CLOSE_MODAL:
        draft.cart.isModalOpened = false;
        break;

      case actionsType.CART_SET_FROM_ORDER_REQUEST:
        draft.cart.items = {};
        break;

      case actionsType.CART_SET_FROM_ORDER_SUCCESS:
      case actionsType.ADD_ORDER_FROM_PRESCRIPTION_SUCCESS:
        draft.cart.items = action.items;
        break;

      case actionsType.CART_SET_FROM_ORDER_FAIL:
        draft.cart.items = {};
        break;

      case actionsType.CART_ADD_ITEM:
        draft.checkout = null;
        // eslint-disable-next-line no-case-declarations
        const items = state.cart.items || {};
        draft.cart.items = {
          ...items,
          [action.item.id]:
                        items[action.item.id] ? {
                          ...action.item,
                          quantity: items[action.item.id].quantity + action.quantity
                        }
                          : {
                            ...action.item,
                            quantity: action.quantity
                          }
        };
        break;

      case actionsType.CART_SET_ITEM_QUANTITY:
        draft.checkout = null;
        draft.cart.items = {
          ...state.cart.items,
          [action.item.id]: {
            ...action.item,
            quantity: action.quantity
          }
        };
        if(action.quantity === 0) {
          delete draft.cart.items[action.item.id];
        }
        break;

      case actionsType.SIGNIN_SESSION_REQUEST:
      case actionsType.SIGNIN_SESSION_BY_DIRECT_LINK_REQUEST:
        draft.sessionError = false;
        break;

      case actionsType.SIGNIN_SESSION_SUCCESS:
      case actionsType.SIGNIN_SESSION_BY_DIRECT_LINK_SUCCESS:
        draft.session = {...action.session, updating: false};
        draft.sessionError = false;
        break;

      case actionsType.SIGNIN_SESSION_FAIL:
      case actionsType.SIGNIN_SESSION_BY_DIRECT_LINK_FAIL:
        draft.session = null;
        Cookie.remove('session');
        draft.sessionError = true;
        break;

      case actionsType.UPDATE_SESSION_REQUEST:
        draft.session.updating = action.updating;
        break;

      case actionsType.SET_SHIPPING_ADDRESS_SELECTED:
        draft.cart.shipping.address = action.shippingAddress;
        draft.cart.shipping.methods = [];
        draft.cart.shipping.method = null;
        draft.cart.shipping.pickupPoint = null;
        //draft.checkout = null;
        Cookie.set('shipping-address', action.shippingAddress);
        Cookie.remove('shipping-method');
        Cookie.remove('shipping-pickup-point');
        break;

      case actionsType.SET_SHIPPING_METHOD_SELECTED:
        draft.cart.shipping.method = action.shippingMethod;
        draft.cart.shipping.pickupPoint = null;
        //draft.checkout = null;
        Cookie.set('shipping-method', action.shippingMethod);
        Cookie.remove('shipping-pickup-point');
        break;

      case actionsType.SET_SHIPPING_PICKUP_POINT_SELECTED:
        draft.cart.shipping.method = action.shippingMethod;
        draft.cart.shipping.pickupPoint = action.pickupPoint;
        //draft.checkout = null;
        Cookie.set('shipping-method', action.shippingMethod);
        Cookie.set('shipping-pickup-point', {
          id: action.pickupPoint.id,
          name: action.pickupPoint.name,
          type: action.pickupPoint.type,
          street: action.pickupPoint.street,
          number: action.pickupPoint.number,
          zip: action.pickupPoint.zip,
          city: action.pickupPoint.city,
          longitude: action.pickupPoint.longitude,
          latitude: action.pickupPoint.latitude,
          country: action.pickupPoint.country,
        });
        break;

      case actionsType.SET_USE_WALLET_SELECTED:
        draft.cart.useWallet = action.useWallet;
        break;

      case actionsType.GET_SHIPPING_METHODS_REQUEST:
        draft.cart.shipping.methodsRequested = true;
        break;

      case actionsType.GET_SHIPPING_METHODS_SUCCESS:
        draft.cart.shipping.methodsRequested = false;
        draft.cart.shipping.methods = action.shippingMethods;
        draft.cart.shipping.method = draft.cart.shipping.method
          ? draft.cart.shipping.method
          : action.shippingMethods.find(m => m.atHome && m.carrier === "bpost")
            || action.shippingMethods.find(m => m.atHome);
        break;

      case actionsType.GET_SHIPPING_METHODS_FAIL:
        draft.cart.shipping.methodsRequested = false;
        break;

      case actionsType.GET_CHECKOUT_REQUEST:
        draft.checkout = initialState.shop.checkout;
        break;

      case actionsType.GET_CHECKOUT_SUCCESS:
        draft.promotionCode = action.checkout?.order?.promotionCode ? action.checkout?.order?.promotionCode : "" ;
        draft.checkout = action.checkout;
        break;

      case actionsType.GET_CHECKOUT_FAIL:
        draft.session = null;
        Cookie.remove('session');
        //draft.cart.shipping.address = null;
        //Cookie.remove('shipping-address');
        draft.cart.shipping.method = null;
        Cookie.remove('shipping-method');
        draft.cart.shipping.pickupPoint = null;
        Cookie.remove('shipping-pickup-point');
        draft.checkout = null;
        break;

      case actionsType.CLEAN_CHECKOUT:
      case authenticationActionsType.SIGNOUT:
      case authenticationActionsType.SIGNOUT_ALL_DEVICES:
      case authenticationActionsType.SIGNOUT_INVALID_ACCESS_TOKEN:
        draft.cart.items = {};
        // eslint-disable-next-line no-empty
        try { localStorage.removeItem('cart') } catch (e) {}
        draft.session = null;
        Cookie.remove('session');
        draft.cart.shipping.address = null;
        Cookie.remove('shipping-address');
        draft.cart.shipping.method = null;
        Cookie.remove('shipping-method');
        draft.cart.shipping.pickupPoint = null;
        Cookie.remove('shipping-pickup-point');
        draft.activeCampaign = null;
        Cookie.remove('active-campaign');
        draft.checkout = null;
        break;

      case authenticationActionsType.SIGNIN_CREDENTIALS_SUCCESS:
      case authenticationActionsType.SIGNIN_JWT_SUCCESS:
      case authenticationActionsType.RESTORE_CONTROL_SUCCESS:
      case authenticationActionsType.TAKE_CONTROL_SUCCESS:
      case authenticationActionsType.RELOAD_USER_SUCCESS:
      case authenticationActionsType.UPDATE_USER_SUCCESS:
        draft.products = initialState.shop.products;
        break;

      case actionsType.GET_ORDERS_REQUEST:
        break;

      case actionsType.GET_ORDERS_SUCCESS:
        draft.orders = action.orders;
        break;

      case actionsType.GET_ORDERS_FAIL:
        draft.orders = [];
        break;

      case actionsType.GET_GIFT_CARDS_REQUEST:
        break;

      case actionsType.GET_GIFT_CARDS_SUCCESS:
        draft.giftCards = action.giftCards;
        break;

      case actionsType.GET_GIFT_CARDS_FAIL:
        draft.giftCards = [];
        break;

      case actionsType.SELECT_GIFT_CARD:
        draft.giftCard = [...draft.giftCard, action.giftCard];
        break;

      case actionsType.DELETE_GIFT_CARD:
        draft.giftCard.splice(draft.giftCard.indexOf(action.giftCard), 1);
        break;

      case actionsType.SET_DRAWERS_OPEN:
        switch(action.drawer){
          case "account":
            draft.drawers = {account: action.bool, response: false, readOnlyCheckout: false, checkout: false};
            break;
          case "checkout":
            draft.drawers = {account: false, response: false, readOnlyCheckout: false, checkout: action.bool};
            break;
          case "response":
            draft.drawers = {account: false, response: action.bool, readOnlyCheckout: false, checkout: false};
            break;
          case "readOnlyCheckout":
            draft.drawers = {account: false, response: false, readOnlyCheckout: action.bool, checkout: false};
            break;
        }
        break;

      case actionsType.SET_CHECKOUT_PROCESSING:
        draft.isCheckoutProcessing = action.processing;
        break;

      case actionsType.SET_ELEMENT_FORM_READY:
        draft.elementFormReady = action.ready;
        break;

      case actionsType.SET_PAYMENT_METHOD:
        draft.paymentMethod = action.paymentMethod;
        break;

      case actionsType.SET_PROMOTION_CODE:
        draft.promotionCode = action.promotionCode;
        break;

      case actionsType.SET_ACTIVE_CAMPAIGN:
        draft.activeCampaign = action.data;
        Cookie.set('active-campaign', action.data, 2 * 24 * 60 * 60);
        break;
      case actionsType.ADD_TO_FAVORITES_SUCCESS:
        draft.favoritesAdded = [...draft.favoritesAdded, action.productId];
        //remove productId from favoritesRemoved
        draft.favoritesRemoved = draft.favoritesRemoved.filter(id => id !== action.productId);
        break;

      case actionsType.REMOVE_FROM_FAVORITES_SUCCESS:
        draft.favoritesRemoved = [...draft.favoritesRemoved, action.productId];
        break;

      case actionsType.SET_SHOP_PRODUCT_REQUEST_ANSWERED_EMPTY:
        draft.shopProductRequestAnsweredEmpty = action.value;
        break;

      case actionsType.SET_FROM_INTERNAL_NAVIGATION:
        draft.fromInternalNavigation = action.value;
        break;

      case actionsType.SET_CART_FORBIDDEN:
        draft.cart.isCartForbidden = action.value;
        break;

      default:

    }
  });
