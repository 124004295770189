import React, {useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import {green} from "@mui/material/colors";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

import Reviews from "./Reviews";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      style={{width: '100%'}}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 3,
            color: '#7C909B'
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
    value: index
  };
}

const TabPanelContent = ({product, isPro, pdfs}) => {

  const {t} = useTranslation();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (

    <Box
      sx={{
        flexGrow: 1,
        flexDirection: {xs: 'column', sm: 'column', md: 'column', lg: 'column', xl: 'column'},
        bgcolor: 'background.paper',
        display: 'flex',
        fontWeight: '300',
        whiteSpace: 'pre-wrap',
        tabSize: '4',
        mt: 2,
        '& strong': {
          fontWeight: 'bold',
          color: '#3C515E'
        },
        '& .MuiTypography-root p': {
          m: 0,
          tabSize: '4',
        },
        '& .MuiTypography-root ul': {
          mt: 0,
        },
        '& .MuiTypography-body1': {
          fontFamily: 'Open sans',
        },
        '& .MuiTypography-body1 p, .MuiTypography-body1 span': {
          color: '#7C909B !important',
        },
        '& .ql-indent-1': {
          marginLeft: '1.5em !important',
        },
        '& .ql-indent-2': {
          marginLeft: '3em !important',
        },
        '& .ql-indent-3': {
          marginLeft: '4.5em !important',
        },
        '& .ql-indent-4': {
          marginLeft: '6em !important',
        },
        '& .ql-indent-5': {
          marginLeft: '7.5em !important',
        }
      }}>
      <Tabs
        orientation={"horizontal"}
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Product menu"
        sx={{
          borderBottom: '1px dashed #7C909B',
          '& .MuiChip-root': {
            m: {sm: '0 0 0 8px', md: '0 0 0 8px', lg: '0 0 0 8px', xl: '0 0 0 8px', xxl: '0 0 0 8px'},
          },
          '& .MuiChip-root > span': {
            fontSize: '1rem',
            pr: '6px',
            pl: '6px',
          },
          '& .MuiTabs-indicator': {
            display: 'none'
          }
        }}
      >
        <Chip onClick={() => setValue(0)} label={isPro ? t("indicationsPro") : t("indications")}{...a11yProps(0)} variant={value === 0 ? 'outlined' : 'default'} sx={{color: value === 0 ? '#8CBD23' : '#3C515E'}}/>
        {/*
        <Chip onClick={() => setValue(1)} label={t("shortIndications")}{...a11yProps(1)} variant={value === 1 ? 'outlined' : 'default'} sx={{color: value === 1 ? '#8CBD23' : '#3C515E'}}/>
*/}
        <Chip onClick={() => setValue(2)} label={isPro ? t("descriptionPro") : t("description")}{...a11yProps(2)} variant={value === 2 ? 'outlined' : 'default'} sx={{color: value === 2 ? '#8CBD23' : '#3C515E'}}/>
        <Chip onClick={() => setValue(3)} label={t("usageTips")}{...a11yProps(3)} variant={value === 3 ? 'outlined' : 'default'} sx={{color: value === 3 ? '#8CBD23' : '#3C515E'}}/>
        <Chip onClick={() => setValue(4)} label={t("composition")}{...a11yProps(4)} variant={value === 4 ? 'outlined' : 'default'} sx={{color: value === 4 ? '#8CBD23' : '#3C515E'}}/>
        <Chip onClick={() => setValue(5)} label={t("ingredients")}{...a11yProps(5)} variant={value === 5 ? 'outlined' : 'default'} sx={{color: value === 5 ? '#8CBD23' : '#3C515E'}}/>
        <Chip onClick={() => setValue(6)} label={t('guaranteedWithout')}{...a11yProps(6)} variant={value === 6 ? 'outlined' : 'default'} sx={{color: value === 6 ? '#8CBD23' : '#3C515E'}}/>
        <Chip onClick={() => setValue(7)} label={t("conservation")}{...a11yProps(7)} variant={value === 7 ? 'outlined' : 'default'} sx={{color: value === 7 ? '#8CBD23' : '#3C515E'}}/>
        <Chip onClick={() => setValue(8)} label={t("contraindications")}{...a11yProps(8)} variant={value === 8 ? 'outlined' : 'default'} sx={{color: value === 8 ? '#8CBD23' : '#3C515E'}}/>
        {product.reviewsRating && (
          <Chip onClick={() => setValue(9)} label={t("reviews")}{...a11yProps(9)} variant={value === 9 ? 'outlined' : 'default'} sx={{color: value === 9 ? '#8CBD23' : '#3C515E'}}/>
        )}
        {isPro && (
          <Chip onClick={() => setValue(10)} label={t("scientificDoc")}{...a11yProps(10)} variant={value === 10 ? 'outlined' : 'default'} sx={{color: value === 10 ? '#8CBD23' : '#3C515E'}}/>
        )}
      </Tabs>
      <TabPanel value={value} index={0}>
        <Typography component={"div"} dangerouslySetInnerHTML={{ __html: product.detail.indications }}/>
      </TabPanel>{/*
      <TabPanel value={value} index={1}>
        <Typography component={"div"} dangerouslySetInnerHTML={{ __html: product.detail.shortIndications }}/>
      </TabPanel>*/}
      <TabPanel value={value} index={2}>
        <Typography component={"div"} dangerouslySetInnerHTML={{ __html: product.detail.description }}/>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Typography component={"div"} dangerouslySetInnerHTML={{ __html: product.detail.precautionsForUse }}/>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Typography component={"div"} dangerouslySetInnerHTML={{ __html: product.detail.composition }}/>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Typography component={"div"} dangerouslySetInnerHTML={{ __html: product.detail.ingredients }}/>
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Typography component={"div"} dangerouslySetInnerHTML={{ __html: product.detail.guaranteedWithout }}/>
      </TabPanel>
      <TabPanel value={value} index={7}>
        <Typography component={"div"} dangerouslySetInnerHTML={{ __html: product.detail.conservation }}/>
      </TabPanel>
      <TabPanel value={value} index={8}>
        <Typography component={"div"} dangerouslySetInnerHTML={{ __html: product.detail.contraindications }}/>
      </TabPanel>
      {product.reviewsRating && (
        <TabPanel value={value} index={9}>
          <Typography component="h4" variant="h5">
            <Trans i18nKey="reviews">Reviews</Trans>
          </Typography>
          <div>
            <Typography component="span" variant="h2">
              {parseFloat(product.reviewsRating).toFixed(1)}
            </Typography> <Typography component="span" variant="h4">
            /5
            </Typography>
          </div>
          <Typography component="I">
            <Trans i18nKey={"calculatedFromReview"} values={{count: product.reviewsCount}}>Calculated
                            from {product.reviewsCount} customer review(s)</Trans>
          </Typography>
          <Divider/>
          {value === 9 && (
            <Reviews product={product}/>
          )}
        </TabPanel>
      )}
      {isPro && (
        <TabPanel value={value} index={10}>
          <List>
            {pdfs && pdfs?.length > 0 && pdfs.map(pdf => (
              <>
                <ListItem
                  component={"a"}
                  target={"_blank"}
                  href={pdf.url}
                  sx={{
                    textDecoration:"none",
                    color: "inherit"
                  }}
                >
                  <ListItemIcon sx={{fill: green[800], color: green[800]}}>
                    <DescriptionOutlinedIcon/>
                  </ListItemIcon>
                  <ListItemText primary={pdf.key}/>
                </ListItem>
                <Divider/>
              </>
            ))}
          </List>
        </TabPanel>
      )}
    </Box>
  );
};

export default TabPanelContent;
