import React, {useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation} from "react-router-dom";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import CameraTwoToneIcon from '@mui/icons-material/CameraTwoTone';
import CardGiftcardTwoToneIcon from "@mui/icons-material/CardGiftcardTwoTone";
import CreditCardTwoToneIcon from '@mui/icons-material/CreditCardTwoTone';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import InsertChartTwoToneIcon from '@mui/icons-material/InsertChartTwoTone';
import LocalShippingTwoToneIcon from "@mui/icons-material/LocalShippingTwoTone";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PhoneTwoToneIcon from "@mui/icons-material/PhoneTwoTone";
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import ShoppingBasketTwoToneIcon from "@mui/icons-material/ShoppingBasketTwoTone";
import TransferWithinAStationTwoToneIcon from '@mui/icons-material/TransferWithinAStationTwoTone';
import {ListItemButton} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { red } from "@mui/material/colors";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import {SnackbarSuccess} from "../../../../helpers/snackbar";
import useMoment from "../../../../helpers/useMoment";
import {authenticationActions} from "../../../Authentication/store/actions";
import {EMPLOYEE_ROLE} from "../../../Authentication/store/constants";
import {isMegaAdmin} from "../../../Authentication/store/selectors";
import {authenticationService} from "../../../Authentication/store/services";

import UserCreditModal from "./UserCreditModal";
import UserDeleteModal from "./UserDeleteModal";
import UserTransferModal from "./UserTransferModal";

const useStyles = makeStyles(() => ({
  root: {
    overflow: 'auto',
    '& .nowrap': {
      whiteSpace: 'nowrap'
    },
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
    '& .MuiCollapse-container': {

      '& .MuiListItem-button': {
        height: 50,
        paddingLeft: 50
      }
    },
    '& .MuiListItemIcon-root': {
      minWidth: 40
    },
    '& .MuiListItem-button': {
      height: 60,
      '& .MuiSvgIcon-root': {
        fontSize: '2em',
        fill: red[900]
      }
    },
    '& .MuiAvatar-colorDefault': {
      margin: 'auto',
      width: 150,
      height: 150,
      marginTop: 20,
      marginBottom: 20,
      position: 'relative',
      '& img': {
        width: 150,
        height: 150,
      },
      '& button': {
        position: 'absolute',
        top: 45,
        left: 0,
        display: 'none'
      },
      '& p': {
        position: 'absolute',
        top: 65,
        left: 65,
      },
      '&:hover': {
        '& button': {
          display: 'inline'
        }
      }
    }
  },
  labelRole: {
    width: '120px',
    display: 'block',
    margin: 'auto',
    color: 'rgb(34, 154, 22)',
    background: 'rgba(84, 214, 44, 0.16)',
    borderRadius: '5px',
    '& .MuiTypography-root': {
      fontWeight: 600,
    }
  }
}));

const UserActionsCard = ({user, onChange}) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const location = useLocation();
  const moment = useMoment();
  const userIsMegaAdmin = useSelector(isMegaAdmin);
  const [isTransferModalOpen, setTransferModalOpen] = useState(false);
  const [isUserDeleteModalOpen, setUserDeleteModalOpen] = useState(false);
  const [isCreditModalOpen, setCreditModalOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {

    const match = location.pathname.split(`/${user.id}`);
    setSelected(match[1] || '');
  }, [location.pathname]);

  const handleTakeControl = () => {

    dispatch(authenticationActions.takeControlRequest(user.id));
  };

  const handleCreditModalOpen = () => {
    setCreditModalOpen(true);
  };

  const handleTransferModalOpen = () => {
    setTransferModalOpen(true);
  };

  const handleUserTransferred = () => {
    onChange();
  };

  const handleUserCredited = () => {
    onChange();
  };

  const handleSendWelcomeEmail = () => {

    authenticationService.passwordRecovery(user.email).then(() => {
      SnackbarSuccess("Email envoyé");
    })
      .catch(e => {
        console.log("e", e);
      });
  };

  const handleUserDeleteModalOpen = () => {
    setUserDeleteModalOpen(true);
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Avatar>
              <img src={`${user.profilePicture}`} alt=""/>
            </Avatar>
            <Typography variant={"h5"} align={"center"}
              gutterBottom>{user.firstName} {user.lastName.toUpperCase()}</Typography>
            <Box className={classes.labelRole}>
              <Typography variant={"body1"} align={"center"} gutterBottom>{t(user.role)}</Typography>
            </Box>
            {!!user.company && (
              <Typography variant={"h5"} align={"center"} gutterBottom>{user.company}</Typography>
            )}
            <Typography className={"createdAt"} align={"center"}>
              <Trans i18nKey={"createdAt"} values={{date: moment(user.createdAt).format('LL')}}>Created
                                at {moment(user.createdAt).format('LL')}</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="h3" variant={"h6"}>
              Actions :
            </Typography>
            <List>
              <ListItemButton component={Link} to={`/admin/users/${user.id}`} selected={selected === ""}>
                <ListItemIcon>
                  <AccountCircleTwoToneIcon/>
                </ListItemIcon>
                <ListItemText primary={"Informations générales"}/>
              </ListItemButton>
              <Divider/>
              <ListItemButton component={Link} to={`/admin/users/${user.id}/update`}
                selected={selected === "/update"}>
                <ListItemIcon>
                  <EditTwoToneIcon/>
                </ListItemIcon>
                <ListItemText primary={"Modifier les informations"}/>
              </ListItemButton>
              <Divider/>
              {user.role === EMPLOYEE_ROLE && (
                <>
                  <ListItemButton component={Link} to={`/admin/users/${user.id}/authorizations`}
                    selected={selected === "/authorizations"}>
                    <ListItemIcon>
                      <LockOpenIcon/>
                    </ListItemIcon>
                    <ListItemText primary={"Modifier les authorisations"}/>
                  </ListItemButton>
                  <Divider/>
                </>
              )}
              {userIsMegaAdmin && (
                <>
                  <ListItemButton onClick={handleTakeControl}>
                    <ListItemIcon>
                      <CameraTwoToneIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Prendre le controle"/>
                  </ListItemButton>
                  <Divider/>
                </>
              )}
              <ListItemButton component={"a"} href={`tel:${user.phone}`}>
                <ListItemIcon>
                  <PhoneTwoToneIcon/>
                </ListItemIcon>
                <ListItemText primary={t("call")}/>
              </ListItemButton>
              <Divider/>
              <ListItemButton component={"a"} href={`mailto:${user.email}`}>
                <ListItemIcon>
                  <SendTwoToneIcon/>
                </ListItemIcon>
                <ListItemText primary={t("sendEmail")}/>
              </ListItemButton>
              <Divider/>
              <ListItemButton component={Link} to={`/admin/users/${user.id}/orders`}
                selected={selected === "/orders"}>
                <ListItemIcon>
                  <ShoppingBasketTwoToneIcon/>
                </ListItemIcon>
                <ListItemText primary={"Commandes"}/>
              </ListItemButton>
              <Divider/>
              <ListItemButton component={Link} to={`/admin/users/${user.id}/shipping`}
                selected={selected === "/shipping"}>
                <ListItemIcon>
                  <LocalShippingTwoToneIcon/>
                </ListItemIcon>
                <ListItemText primary={"Adresses"}/>
              </ListItemButton>
              <Divider/>
              {user.isPro && (
                <>
                  <ListItemButton component={Link} to={`/admin/users/${user.id}/stats`}
                    selected={selected === "/stats"}>
                    <ListItemIcon>
                      <InsertChartTwoToneIcon/>
                    </ListItemIcon>
                    <ListItemText primary={"Statistiques"}/>
                  </ListItemButton>
                  <Divider/>
                </>
              )}
              <>
                <ListItemButton component={Link} to={`/admin/users/${user.id}/gifts`}
                  selected={selected === "/gifts"}>
                  <ListItemIcon>
                    <CardGiftcardTwoToneIcon/>
                  </ListItemIcon>
                  <ListItemText primary={"Cartes cadeaux et bons d'achats"}/>
                </ListItemButton>
                <Divider/>
              </>
              <ListItemButton onClick={handleCreditModalOpen}>
                <ListItemIcon>
                  <CreditCardTwoToneIcon/>
                </ListItemIcon>
                <ListItemText primary={"Modifier les points"}/>
              </ListItemButton>
              <Divider/>
              <ListItemButton onClick={handleTransferModalOpen}>
                <ListItemIcon>
                  <TransferWithinAStationTwoToneIcon/>
                </ListItemIcon>
                <ListItemText primary={"Transférer"}/>
              </ListItemButton>
              <Divider/>
              <ListItemButton onClick={handleSendWelcomeEmail}>
                <ListItemIcon>
                  <SendTwoToneIcon/>
                </ListItemIcon>
                <ListItemText primary="Renvoyer les identifiants par e-mail"/>
              </ListItemButton>
              <ListItemButton onClick={handleUserDeleteModalOpen}>
                <ListItemIcon>
                  <DeleteForeverIcon/>
                </ListItemIcon>
                <ListItemText primary="Supprimer le compte"/>
              </ListItemButton>
            </List>
          </Grid>
        </Grid>
      </CardContent>
      <UserTransferModal user={user} setOpen={setTransferModalOpen} open={isTransferModalOpen}
        onSuccess={handleUserTransferred}/>
      <UserDeleteModal user={user} setOpen={setUserDeleteModalOpen} open={isUserDeleteModalOpen}/>
      <UserCreditModal user={user} setOpen={setCreditModalOpen} open={isCreditModalOpen}
        onSuccess={handleUserCredited}/>
    </Card>
  );
};
export default UserActionsCard;
