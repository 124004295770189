import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import CircleIcon from "@mui/icons-material/Circle.js";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import moment from "moment/moment";

import NumberTextField from "../../../../components/NumberTextField";
import FormValidator from "../../../../components/Validators/FormValidator";
import SelectValidator from "../../../../components/Validators/SelectValidator";
import TextFieldValidator from "../../../../components/Validators/TextFieldValidator";
import {SnackbarError, SnackbarFormattedError} from "../../../../helpers/snackbar";
import {history} from "../../../../helpers/store";
import {specialities} from "../../../../helpers/utils";
import {
  ADMIN_ROLE, CUSTOMER_ROLE, DELEGATE_ROLE, DOCTOR_ROLE,
  EMPLOYEE_ROLE, PROSPECT_ROLE, THERAPIST_ROLE} from "../../../Authentication/store/constants";
import {userSelector} from "../../../Authentication/store/selectors";
import {shopService} from "../../Shop/store/services";
import {userService} from "../store/services";

import DelegateCommissionsBrandsFrom from "./DelegateCommissionsBrandsFrom";
import SearchCheckboxAutocomplete from "./SearchCheckboxAutocomplete";
import SearchUserAutocomplete from "./SearchUserAutocomplete";

const UserUpdateCard = ({user, onChange}) => {

  const {t} = useTranslation();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [delegatesIds, setDelegatesIds] = useState([]);
  const [delegates, setDelegates] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [delegatesDetails, setDelegatesDetails] = useState({});
  const [brandDetails, setBrandDetails] = useState({});
  const [email, setEmail] = useState(user?.email);
  const operator = useSelector(userSelector);
  const [commissionCategories, setCommissionCategories] = useState([]);
  const [switchedToPro, setSwitchedToPro] = useState(false);
  const [userValues, setUserValues] = useState({
    ...user,
    birthDate: moment(user.birthDate).format('YYYY-MM-DD'),
    userDiscountId: !user.userDiscountId && user?.pro?.proDiscountToClientId ? user.pro.proDiscountToClientId : user.userDiscountId,
    userDiscount: !user.userDiscount && user?.pro?.proDiscountToClient ? user.pro.proDiscountToClient : user.userDiscount,
  });

  useEffect(() => {

    let newBrandDetails = {};
    (() => user?.brandCommissions.map(commission => {
      newBrandDetails[commission.productBrandId] = commission.percentage;
    }))();
    (async () => {
      setDiscounts(await shopService.getDiscounts());
    })();

    setBrandDetails(newBrandDetails);

  }, []);

  useEffect(() => {

    (async () => {

      let fetchedCommissionCategories = await shopService.findCommissionCategories();
      setCommissionCategories(fetchedCommissionCategories);
    })();
  }, []);

  useEffect(() => {
    if(switchedToPro) {
      setUserValues({
        ...userValues,
        proDiscountToClientId: "e7355f25-d97a-4a6e-af9b-59333d11bb2d",
        proDiscountToClient: discounts?.find(d => d.id === "e7355f25-d97a-4a6e-af9b-59333d11bb2d"),
        commissionCategoryId: "ef06ac87-a867-4eb8-8329-92675e42c5df",
        commissionCategory: commissionCategories?.find(d => d.id === "ef06ac87-a867-4eb8-8329-92675e42c5df")
      });
    } else {
      setUserValues({
        ...userValues,
        proDiscountToClientId: null,
        proDiscountToClient: null,
        commissionCategoryId: null,
        commissionCategory: null
      });
    }
  }, [userValues?.role]);

  useEffect(() => {
    if (userValues.delegates) {
      let newDelegatesDetails = {};
      setDelegatesIds(userValues.delegates.map(delegate => delegate.id));

      (() => userValues.delegates.map(delegate => {
        newDelegatesDetails[delegate.id] = {commissionStartFrom: delegate.pro_delegate.commissionStartFrom, commissionEndAt: delegate.pro_delegate.commissionEndAt};
        setDelegatesDetails(newDelegatesDetails);
      })
      )();
    }
  }, []);

  useEffect(() => {
    if (delegatesIds.length) {
      userService.getUserByIds(delegatesIds).then(users => {
        setDelegates(users);
      })
        .catch(e => {
          SnackbarFormattedError(e.error);
        });
    }

  }, [delegatesIds.length]);

  useEffect(() => {
    setUserValues({
      ...userValues,
      email: userValues.noEmail ? `${userValues.lastName.normalize("NFD").replace(/ /g, '.').replace(/\//g, '.').replace(/[^\-\w\s]/gi, '').toLowerCase()}.${userValues.firstName.normalize("NFD").replace(/ /g, '.').replace(/\//g, '.').replace(/[^\-\w\s]/gi, '').toLowerCase()}@nomail.com`.toLowerCase() : email
    });
  }, [userValues.noEmail, email, userValues.firstName, userValues.lastName]);

  const handleProSelect = (e, value) => {
    if (value && value.id) {
      setUserValues({
        ...userValues,
        proId: value.id,
        pro: value,
        userDiscountId: value.proDiscountToClient.id,
        userDiscount: value.proDiscountToClient
      });
    }
  };

  const handleReferentSelect = (e, value) => {
    if (!value || !e) return;

    setUserValues({
      ...userValues,
      referentId: value.id
    });
  };

  const handleDelegateSelect = (e, value) => {

    let ids = value.map(item => item.id);
    setDelegatesIds(ids);
    let details = {};
    ids.map(id => {
      if(!delegatesDetails[id]){
        details[id] = {
          commissionStartFrom: 0,
          commissionEndAt: 0
        };
      } else {
        details[id] = delegatesDetails[id];
      }
      setDelegatesDetails(details);
    });
  };

  const handleChangeDelegateCommissionThreshold = (delegateId, e) => {
    setDelegatesDetails({
      ...delegatesDetails,
      [delegateId]: {
        ...delegatesDetails[delegateId],
        [e.target.name]: parseFloat(e.target.value)
      }
    });
  };

  const handleChange = (e) => {
    setUserValues({
      ...userValues,
      [e.target.name]: e.target.value
    });
  };

  const handleChangeNumberTextFieldValue = (e) => {

    setUserValues({
      ...userValues,
      [e.target.name]: parseFloat(e.target.value)
    });
  };
  const handleChangeRole = (e) => {

    setUserValues({
      ...userValues,
      [e.target.name]: e.target.value
    });
    if(e.target.value === DOCTOR_ROLE || e.target.value === THERAPIST_ROLE) {
      setSwitchedToPro(true);
    } else {
      setSwitchedToPro(false);
    }
  };

  const handleChangeEmailValue = (e) => {
    if(!e.target.value) {
      setEmail("");
      return;
    }
    setEmail(e.target.value);
  };

  const handleChangeNoEmail = (value) => {
    setUserValues({
      ...userValues,
      noEmail: value,
    });
  };
  const handleChangeNoBirthDate = (value) => {
    setUserValues({
      ...userValues,
      noBirthDate: value,
      birthDate: "2000-01-01",
    });
  };

  const handleChangeCommissionCategoryCheckboxAutocomplete = (e, value) => {
    if (!value || !e) return;
    setUserValues({
      ...userValues,
      commissionCategoryId: value.id,
      commissionCategory: value
    });
  };

  const handleChangeDiscountCheckboxAutocomplete = (e, value) => {
    if (!value || !e) return;
    setUserValues({
      ...userValues,
      userDiscountId: value.id,
      userDiscount: value
    });
  };

  const handleChangeProDiscountCheckboxAutocomplete = (e, value) => {
    if (!value || !e) return;

    setUserValues({
      ...userValues,
      proDiscountToClientId: value.id,
      proDiscountToClient: value
    });
  };

  const handleChangeAllowInstantTransfersCheckbox = (e, value) => {

    setUserValues({
      ...userValues,
      allowInstantTransfers: value,
    });
  };

  const handleSubmit = () => {

    setFormSubmitted(true);
    if (((userValues.role === DOCTOR_ROLE || userValues.role === THERAPIST_ROLE) && !delegatesIds.length)) {
      SnackbarError("La sélection d'un délégué est obligatoire pour les professionnels de santé et les thérapeutes");
      setFormSubmitted(false);
      return false;
    }
    let data = userValues;

    if (brandDetails) {
      data.brandDetails = brandDetails;
    }
    if (delegatesDetails) {
      data.delegatesDetails = delegatesDetails;
    }

    userService.updateUser(userValues.id, data).then(user => {
      onChange();
      setFormSubmitted(false);
      history.push(`/admin/users/${user.id}`);
    })
      .catch(e => {
        SnackbarFormattedError(e.error);
        setFormSubmitted(false);
      });
  };

  return (
    <Card>
      <CardContent>
        <FormValidator
          onSubmit={handleSubmit}
          onError={errors => {
            setFormSubmitted(true);
            console.log('ERRORS', errors);
            setTimeout(() => setFormSubmitted(false), 1000);
          }}
          noValidate
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SelectValidator
                fullWidth
                name="role"
                label={"Role"}
                value={userValues.role}
                onChange={handleChangeRole}
                required
              >
                <MenuItem value={PROSPECT_ROLE}>
                  {t(PROSPECT_ROLE)}
                </MenuItem>
                <MenuItem value={CUSTOMER_ROLE}>
                  {t(CUSTOMER_ROLE)}
                </MenuItem>
                <MenuItem value={DELEGATE_ROLE}>
                  {t(DELEGATE_ROLE)}
                </MenuItem>
                <MenuItem value={DOCTOR_ROLE}>
                  {t(DOCTOR_ROLE)}
                </MenuItem>
                <MenuItem value={THERAPIST_ROLE}>
                  {t(THERAPIST_ROLE)}
                </MenuItem>
                <MenuItem value={EMPLOYEE_ROLE}>
                  {t(EMPLOYEE_ROLE)}
                </MenuItem>
                <MenuItem value={ADMIN_ROLE}>
                  {t(ADMIN_ROLE)}
                </MenuItem>
              </SelectValidator>
            </Grid>
            <Grid item xs={12}>
              <SelectValidator
                fullWidth
                name="gender"
                label={"Genre"}
                value={userValues.gender}
                onChange={handleChange}
                required
              >
                <MenuItem value="mister">
                  Monsieur
                </MenuItem>
                <MenuItem value="miss">
                  Madame
                </MenuItem>
                <MenuItem value="none">
                  Non genré
                </MenuItem>
              </SelectValidator>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldValidator
                fullWidth
                name="lastName"
                label={t("lastName")}
                value={userValues.lastName ? userValues.lastName : ""}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldValidator
                fullWidth
                name="firstName"
                label={t("firstName")}
                value={userValues.firstName ? userValues.firstName : ""}
                onChange={handleChange}
                required
              />
            </Grid>
            {(userValues.role === DOCTOR_ROLE || userValues.role === THERAPIST_ROLE) && (
              <>
                <Grid item xs={8}>
                  {!userValues.noBirthDate ?
                    <TextFieldValidator
                      fullWidth
                      required
                      name="birthDate"
                      label={t("birthDate")}
                      type="date"
                      value={userValues?.birthDate || ""}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    :
                    <TextField
                      disabled
                      fullWidth
                      label={t("birthDate")}
                      defaultValue={'**/**/****'}
                    />
                  }
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={userValues.noBirthDate}
                        onChange={() => {
                          handleChangeNoBirthDate(!userValues.noBirthDate);
                        }}
                        value="true" color="primary"/>
                    }
                    label={"Ne souhaite pas la communiquer"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldValidator
                    id={"phone"}
                    fullWidth
                    required
                    name="phone"
                    type="tel"
                    label={t("phone")}
                    value={userValues?.phone || ""}
                    onChange={handleChange}
                  />
                </Grid>
                {!userValues.noEmail
                  ?<Grid item xs={8}>
                    <TextFieldValidator
                      fullWidth
                      id="email"
                      type="email"
                      name="email"
                      label={t("email")}
                      value={email}
                      onChange={handleChangeEmailValue}
                      required={!userValues.noEmail}
                    />
                  </Grid>
                  :<Grid item xs={8}>
                    <TextField
                      fullWidth
                      id="email"
                      type="email"
                      name="email"
                      label={t("email")}
                      value={userValues.email}
                      onChange={handleChangeEmailValue}
                      required={false}
                    />
                  </Grid>}
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={userValues.noEmail}
                        onChange={() => {
                          handleChangeNoEmail(!userValues.noEmail);
                        }}
                        value="true" color="primary"/>
                    }
                    label={"Pas d'email"}
                    name={"noEmail"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldValidator
                    fullWidth
                    id="password"
                    type="password"
                    name="password"
                    label={"Mot de passe"}
                    value={userValues?.password ?? ""}
                    onChange={handleChange}
                  />
                </Grid>
                <Divider orientation={"horizontal"}/>
                <Grid item xs={12}>
                  <SelectValidator
                    fullWidth
                    name="speciality"
                    label={"Spécialité"}
                    value={userValues.speciality}
                    onChange={handleChange}
                  >
                    {specialities.map((speciality, index) => (
                      <MenuItem key={index} value={speciality}>
                        {speciality}
                      </MenuItem>
                    ))}
                  </SelectValidator>
                </Grid>
                <Grid item xs={12}>
                  <SelectValidator
                    fullWidth
                    name="potential"
                    label={"Potentiel"}
                    value={userValues.potential}
                    onChange={handleChange}
                  >
                    <MenuItem key={"VIP"} value={"#03a9f4"}>
                      <CircleIcon sx={{color: "#03a9f4"}}/> VIP
                    </MenuItem>
                    <MenuItem key={"Gros Potentiel"} value={"#ff9800"}>
                      <CircleIcon sx={{color: "#ff9800"}}/> Gros Potentiel
                    </MenuItem>
                    <MenuItem key={"Moyen Potentiel"} value={"#ffeb3b"}>
                      <CircleIcon sx={{color: "#ffeb3b"}}/> Moyen Potentiel
                    </MenuItem>
                    <MenuItem key={"Petit Potentiel"} value={"#4caf50"}>
                      <CircleIcon sx={{color: "#4caf50"}}/> Petit Potentiel
                    </MenuItem>
                    <MenuItem key={"Inconnu"} value={"#9fa8da"}>
                      <CircleIcon sx={{color: "#9fa8da"}}/> Inconnu
                    </MenuItem>
                  </SelectValidator>
                </Grid>
                {operator?.role === "admin" && (
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={userValues.allowInstantTransfers}
                          onChange={handleChangeAllowInstantTransfersCheckbox}
                          value="false" color="primary"/>
                      }
                      label={"Autoriser les transferts différés"}
                      name={"allowInstantTransfers"}
                    />
                  </Grid>
                )}
                <Divider orientation={"horizontal"}/>
                <Grid item xs={12} key={`${userValues.userDiscountId}-discount`}>
                  <SearchCheckboxAutocomplete
                    handleChange={handleChangeDiscountCheckboxAutocomplete}
                    getOptionLabel={() => userValues?.userDiscount?.name}
                    selected={[userValues?.userDiscountId]}
                    options={discounts}
                    label={"Remise accordée"}
                  />
                </Grid>
                <Grid item xs={12} key={`${userValues.proDiscountToClientId}-proDiscount`}>
                  <SearchCheckboxAutocomplete
                    handleChange={handleChangeProDiscountCheckboxAutocomplete}
                    getOptionLabel={() => userValues?.proDiscountToClient?.name}
                    selected={[userValues?.proDiscountToClientId]}
                    options={discounts}
                    label={"Remise accordée aux patients"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldValidator
                    fullWidth
                    id="proCode"
                    name="proCode"
                    label={"Code d'enreigstrement en ligne"}
                    value={userValues.proCode}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SearchUserAutocomplete
                    multiple
                    onChange={handleDelegateSelect}
                    defaultValue={userValues.delegates}
                    target={"delegate"}
                    label={"Délégué(s) en charge"}
                  />
                </Grid>
                {!!delegatesIds.length && !!delegates.length && (
                  <Grid item xs={12} key={delegatesIds.length}>
                    <Grid container spacing={2}>
                      {delegates.map(delegate => (
                        <React.Fragment key={delegate.id}>
                          <Grid item xs={12}>
                            <NumberTextField
                              fullWidth
                              name={"commissionStartFrom"}
                              label={`${delegate.firstName} ${delegate.lastName} Commissionné à partir de`}
                              type={'text'}
                              defaultValue={delegates.find(item => item.id === delegate.id)?.pros?.find(pro => pro.id === userValues.id)?.pro_delegate?.commissionStartFrom || 0}
                              value={delegatesDetails?.[delegate.id]?.commissionStartFrom}
                              onChange={(e) => handleChangeDelegateCommissionThreshold(delegate.id, e)}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <NumberTextField
                              fullWidth
                              name={"commissionEndAt"}
                              label={`La commission de ${delegate.firstName} ${delegate.lastName} s'arrête à partir de`}
                              type={'text'}
                              defaultValue={delegates.find(item => item.id === delegate.id)?.pros?.find(pro => pro.id === userValues.id)?.pro_delegate?.commissionEndAt || 0}
                              value={delegatesDetails?.[delegate.id]?.commissionEndAt}
                              onChange={(e) => handleChangeDelegateCommissionThreshold(delegate.id, e)}
                            />
                          </Grid>
                        </React.Fragment>
                      ))}
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <SearchUserAutocomplete
                    onChange={handleReferentSelect}
                    target={"pro"}
                    defaultValue={userValues.referent}
                    label={"Referent"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <NumberTextField
                    fullWidth
                    name="referentCommissionPercentage"
                    label={'Commission de référencement (%)'}
                    defaultValue={0}
                    value={userValues?.referentCommissionPercentage}
                    onChange={handleChangeNumberTextFieldValue}
                  />
                </Grid>

                <Grid item xs={12} key={`${userValues.commissionCategoryId}-commission`}>
                  <SearchCheckboxAutocomplete
                    handleChange={handleChangeCommissionCategoryCheckboxAutocomplete}
                    getOptionLabel={() => userValues?.commissionCategory?.name}
                    selected={[userValues?.commissionCategory?.id]}
                    options={commissionCategories}
                    label={"Type de commission"}
                  />
                </Grid>
                {/*<Grid item xs={12}>
                  <SelectValidator
                    fullWidth
                    name="lang"
                    label={t("mailingAndDataLanguage")}
                    value={userValues.lang}
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value="fr">
                      {t("french")}
                    </MenuItem>
                    <MenuItem value="en">
                      {t("english")}
                    </MenuItem>
                    <MenuItem value="nl">
                      {t("dutch")}
                    </MenuItem>
                  </SelectValidator>
                </Grid>*/}
                <Grid item xs={12}>
                  <TextFieldValidator
                    fullWidth
                    name="company"
                    label={t("company")}
                    value={userValues.company}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldValidator
                    fullWidth
                    name="taxNumber"
                    label={t("taxNumber")}
                    value={userValues.taxNumber}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldValidator
                    fullWidth
                    name="iban"
                    label={"IBAN"}
                    value={userValues.iban}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldValidator
                    fullWidth
                    name="bic"
                    label={"BIC"}
                    value={userValues.bic}
                    onChange={handleChange}
                  />
                </Grid>
              </>
            )}
            {(userValues.role === CUSTOMER_ROLE || userValues.role === PROSPECT_ROLE || userValues.role === ADMIN_ROLE || userValues.role === DELEGATE_ROLE|| userValues.role === EMPLOYEE_ROLE) && (
              <>
                {(userValues.role === CUSTOMER_ROLE || userValues.role === PROSPECT_ROLE) && (
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <SearchUserAutocomplete
                        onChange={handleProSelect}
                        defaultValue={userValues?.pro}
                        target={"pro"}
                        label={"Professionnel en charge"}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12} key={`${userValues.userDiscountId}-discount`}>
                  <SearchCheckboxAutocomplete
                    handleChange={handleChangeDiscountCheckboxAutocomplete}
                    getOptionLabel={() => userValues?.userDiscount?.name}
                    selected={[userValues?.userDiscountId]}
                    options={discounts}
                    label={"Remise accordée"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldValidator
                    fullWidth
                    name="company"
                    label={t("company")}
                    value={userValues.company}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldValidator
                    fullWidth
                    name="taxNumber"
                    label={t("taxNumber")}
                    value={userValues.taxNumber}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldValidator
                    id={"phone"}
                    fullWidth
                    required
                    name="phone"
                    type="tel"
                    label={t("phone")}
                    value={userValues?.phone || ""}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={8}>
                  {!userValues.noBirthDate ?
                    <TextFieldValidator
                      fullWidth
                      required
                      name="birthDate"
                      label={t("birthDate")}
                      type="date"
                      value={userValues?.birthDate || ""}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    :
                    <TextField
                      disabled
                      fullWidth
                      label={t("birthDate")}
                      defaultValue={'**/**/****'}
                    />
                  }
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={userValues.noBirthDate}
                        onChange={() => {
                          handleChangeNoBirthDate(!userValues.noBirthDate);
                        }}
                        value="true" color="primary"/>
                    }
                    label={"Ne souhaite pas la communiquer"}
                  />
                </Grid>
                {!userValues.noEmail
                  ?<Grid item xs={8}>
                    <TextFieldValidator
                      fullWidth
                      id="email"
                      type="email"
                      name="email"
                      label={t("email")}
                      value={email}
                      onChange={handleChangeEmailValue}
                      required={!userValues.noEmail}
                    />
                  </Grid>
                  :<Grid item xs={8}>
                    <TextField
                      fullWidth
                      id="email"
                      type="email"
                      name="email"
                      label={t("email")}
                      value={userValues.email}
                      onChange={handleChangeEmailValue}
                      required={false}
                    />
                  </Grid>}
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={userValues.noEmail}
                        onChange={() => {
                          handleChangeNoEmail(!userValues.noEmail);
                        }}
                        value="true" color="primary"/>
                    }
                    label={"Pas d'email"}
                    name={"noEmail"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldValidator
                    fullWidth
                    id="password"
                    type="password"
                    name="password"
                    label={"Mot de passe"}
                    value={userValues?.password ?? ""}
                    onChange={handleChange}
                  />
                </Grid>
                {(userValues.role === DELEGATE_ROLE) && (
                  <Grid item xs={12}>
                    <DelegateCommissionsBrandsFrom
                      brandDetails={brandDetails}
                      setBrandDetails={setBrandDetails}
                    />
                  </Grid>
                )}
              </>
            )}
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={formSubmitted}
              >
                Modifier l'utilisateur
              </Button>
            </Grid>
          </Grid>
        </FormValidator>
      </CardContent>
    </Card>
  );
};
export default UserUpdateCard;
