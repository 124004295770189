import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FmdGoodSharpIcon from "@mui/icons-material/FmdGoodSharp";
import {Box, IconButton, Stack, Tooltip} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import theme from "../../../../../assets/styles/theme/theme";
import {userSelector} from "../../../../Authentication/store/selectors";
import AddShippingAddressDialog
  from "../../../Account/components/ShippingAddresses/components/AddShippingAddressDialog";
import UpdateShippingAddressDialog from "../../../Account/components/ShippingAddresses/components/UpdateShippingAddressDialog";
import {shopActions} from "../../store/actions";
import {
  shippingAddressSelectedSelector, shippingMethodSelectedSelector, shippingPickupPointSelectedSelector,
  userShippingAddressesSelector
} from "../../store/selectors";
import {shopService} from "../../store/services";

const ShippingAddressSelector = () => {

  const dispatch = useDispatch();
  const {t} = useTranslation();
  const user = useSelector(userSelector);
  const shippingAddresses = useSelector(userShippingAddressesSelector);
  const shippingAddressSelected = useSelector(shippingAddressSelectedSelector);
  const shippingMethodSelected = useSelector(shippingMethodSelectedSelector);
  const shippingPickupPointSelected = useSelector(shippingPickupPointSelectedSelector);
  const [updateShippingAddressSelected, setUpdateShippingAddressSelected] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);

  const [addShippingModalOpen, setAddShippingModalOpen] = useState(false);

  useEffect(() => {
    dispatch(shopActions.getShippingAddressesRequest());
  }, [user]);

  const handleChangeShippingAddress = async (e) => {
    let newShippingAddress = e.id ? shippingAddresses.find(a => a.id === e?.id) : shippingAddresses.find(a => a.id === e?.target?.value);

    dispatch(shopActions.setShippingAddressSelected(newShippingAddress));
    shopService.setCartLog("Le client change d'adresse de livraison",{from:"checkoutDrawer",userId:user?.id, adresse: newShippingAddress?.address}).then().catch();
  };

  const handleOpenUpdateDialog = (address) => () => {
    setUpdateShippingAddressSelected(address);
    setUpdateDialogOpen(true);
    shopService.setCartLog("Le client ouvre le pop de modif d'adresse",{from:"checkoutDrawer",userId:user?.id}).then().catch();
  };

  //handleChangeShippingAddress(shippingAddresses.find(addr => addr.id === e.target.value));
  return (
    <Box sx={{pl: 0, pr: 0}}>
      <Grid container spacing={2}>
        {!!shippingAddressSelected && shippingMethodSelected && shippingPickupPointSelected && (
          <Grid item xs={12}>
            <Card sx={{
              p: 1,
              boxShadow: 'none',
              backgroundColor:'#F5F8FA',
              border:'1px solid #8CBD23',
              borderRadius: '12px',
              '& .MuiSvgIcon-root': {
                fontSize: '2rem',
                color: theme.palette.primary.main ,
              },
              '& .MuiTypography-root': {
                fontSize: '0.9rem',
                color: theme.palette.primary.main,
              }
            }}>
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  fontWeight: "500"
                }}
              >
                <Stack gap={1}>
                  <Typography component={"p"}
                    sx={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
                    {t(shippingMethodSelected?.packetDelivery ? "packetDelivery" : "pickupPoint")}
                  </Typography>
                  <Typography component={"p"}
                    sx={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
                    {t("to")} {` ${user.firstName} ${user.lastName}`}
                  </Typography>
                  <Typography component={"p"}
                    sx={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
                    {shippingPickupPointSelected.name}, {shippingPickupPointSelected.number} {shippingPickupPointSelected.street}, {shippingPickupPointSelected.zip} {shippingPickupPointSelected.city}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        )}

        {shippingAddresses?.map(shippingAddress => (
          <Grid item xs={12} key={shippingAddress.id}>
            <Card
              onClick={() => handleChangeShippingAddress(shippingAddress)}
              sx={{
                p: 1,
                cursor: "pointer",
                boxShadow: (shippingAddress.id === shippingAddressSelected.id) ? 'none' : '6px 6px 12px #00000021',
                backgroundColor: (shippingAddress.id === shippingAddressSelected.id) ? '#F5F8FA' : '#fff',
                border: (shippingAddress.id === shippingAddressSelected.id) ? '1px solid #8CBD23' : 'none',
                borderRadius: '12px',
                '& .MuiSvgIcon-root': {
                  fontSize: '2rem',
                  color: (shippingAddress.id === shippingAddressSelected.id) ? theme.palette.primary.main : '#7C909B',
                },
                '& .MuiTypography-root': {
                  fontSize: '0.9rem',
                  color: (shippingAddress.id === shippingAddressSelected.id) ? theme.palette.primary.main : '#7C909B',
                }
              }}>
              <CardHeader
                title={
                  <Typography component={"p"}>
                    <FmdGoodSharpIcon/>
                    {shippingAddress.name}
                  </Typography>
                }
                action={
                  <IconButton
                    aria-label="edit address"
                    onClick={handleOpenUpdateDialog(shippingAddress)}
                    sx={{
                      color: '#7C909B',
                      background: '#ffffff',
                      borderRadius: '50%',
                      boxShadow: '12px 12px 24px #00000021',
                      padding: '4px',
                    }}
                  >
                    <EditOutlinedIcon/>
                  </IconButton>
                }
              />
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  fontWeight: "500"
                }}
              >
                <Stack gap={1} alignItems={"flex-start"}>
                  <Typography component={"p"}>
                    {shippingAddress.firstName} {shippingAddress.lastName}
                  </Typography>
                  <Typography component={"p"}>
                    {shippingAddress.postalBox}
                  </Typography>
                  <Typography component={"p"}>
                    {shippingAddress.address} {shippingAddress.additionalInfo && (`(${shippingAddress.additionalInfo})`)}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>


      <Stack direction={"row"} gap={1} justifyContent={"flex-end"} sx={{mt: 1}}>
        <Tooltip title={t("addAddress")}>
          <IconButton onClick={() => setAddShippingModalOpen(true)} sx={{
            boxShadow: '6px 6px 12px 3px #00000021',
            borderRadius: '30px'
          }}>
            <AddIcon/>
          </IconButton>
        </Tooltip>
      </Stack>
      <AddShippingAddressDialog dialogOpen={addShippingModalOpen} setDialogOpen={setAddShippingModalOpen}/>
      {updateDialogOpen && updateShippingAddressSelected && (
        <UpdateShippingAddressDialog shippingAddress={updateShippingAddressSelected} dialogOpen={updateDialogOpen} setDialogOpen={setUpdateDialogOpen}/>
      )}
    </Box>
  );
};
export default ShippingAddressSelector;




