import React from "react";
import {Trans} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import {userSelector} from "../../../../../Authentication/store/selectors";
import {shopActions} from "../../../../Shop/store/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDivider-root': {
      margin: -1,
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& .MuiCard-root': {
        overflow: 'auto'
      }
    },
    '& .MuiTypography-subtitle1': {
      maxHeight: 42,
      lineHeight: '20px',
      overflow: 'hidden'
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: 80,
      height: 'auto',
    },
    '& a': {
      textDecoration: 'none'
    },
    '& .MuiButtonBase-root': {
      fontSize: '0.8rem',
      fontFamily: 'Montserrat',
      textTransform: 'capitalize',
      borderRadius: '30px',
      color: '#fff',
      lineHeight: '1.3',
      padding: '8px 8px',
      '&:hover': {
        background: '#fff',
        color: theme.palette.primary.main,
      }
    },
    '& .MuiTypography-root': {
      fontFamily: 'Open sans',
      fontSize: '0.8rem',
      color: '#7C909B',
    }
  },
  listItems: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '30vh',
      overflow: 'auto'
    }
  },
  item: {
    [theme.breakpoints.up('sm')]: {
      '& .MuiListItem-root': {
        borderRight: '1px solid #DDD'
      }
    },
  },
  detailsRowPrice: {
    paddingTop: 20
  },
}));

const PrescriptionDetails = ({prescription, removePrescription}) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  const setCartByOrder = (prescriptionId) => {
    dispatch(shopActions.addOrderFromPrescriptionRequest(prescriptionId));
    dispatch(shopActions.SetDrawersOpen("checkout", true));
  };

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Grid container justify={"space-between"}>
          <Grid item>
            <Grid container spacing={2} alignItems={"center"}>
              {user.id === prescription.userId && prescription.status !== "ordered" && (
                <Grid item>
                  <Button variant={"contained"} onClick={() => setCartByOrder(prescription.id)}
                    color={"primary"}>
                    <Trans i18nKey={"orderPrescription"}>Order the prescription</Trans>
                  </Button>
                </Grid>
              )}
              {prescription.status !== "ordered" && (
                <Grid item>
                  <Button
                    variant={"contained"}
                    onClick={() => removePrescription(prescription.id)}
                    color={"primary"}
                    sx={{
                      fontFamily: 'Open sans',
                      fontSize: '0.9rem !important',
                      display: 'flex',
                      alignItems: 'flex-end',
                    }}
                  >
                    <Trans i18nKey={"delete"}>Supprimer</Trans>
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={5}>
        <List className={classes.listItems}>
          {prescription.products.map((item, key) => (
            <React.Fragment key={key}>
              <ListItem className={classes.item}>
                <Grid container justify={"center"} alignItems={"center"}>
                  <Grid item xs={4}>
                    <Grid container justify={"center"} alignItems={"center"}>
                      <img
                        width={80}
                        height={80}
                        src={item.originalProduct.image[200]}
                        alt={item.originalProduct.reference}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container justify={"center"} alignItems={"center"}>
                      <Grid item xs={8}>
                        <Typography variant="subtitle1">
                          {item.originalProduct.reference}
                        </Typography>
                        <Typography variant="body1">
                          <b>{item.quantity} x</b>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      {item.notes}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              {prescription.products.length > 1 && (
                <Divider/>
              )}
            </React.Fragment>
          ))}
        </List>
      </Grid>
      <Divider orientation="vertical" flexItem/>
    </Grid>
  );
};

export default PrescriptionDetails;
