import React from "react";
import {Box} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import NoSsr from "@mui/material/NoSsr";
import Toolbar from "@mui/material/Toolbar";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

import Header from "../components/Layout/Pro/Header";
import careerReducer, {careerReducerKey} from "../containers/Pro/Career/store/reducer";
import careerSaga, {careerSagaKey} from "../containers/Pro/Career/store/saga";
import commissionsReducer, {commissionsReducerKey} from "../containers/Pro/Commissions/store/reducer";
import commissionsSaga, {commissionsSagaKey} from "../containers/Pro/Commissions/store/saga";
import customersReducer, {customersReducerKey} from "../containers/Pro/Customers/store/reducer";
import customersSaga, {customersSagaKey} from "../containers/Pro/Customers/store/saga";
import networkReducer, {networkReducerKey} from "../containers/Pro/Network/store/reducer";
import networkSaga, {networkSagaKey} from "../containers/Pro/Network/store/saga";
import proShopReducer, {proShopReducerKey} from "../containers/Pro/Shop/store/reducer";
import proShopSaga, {proShopSagaKey} from "../containers/Pro/Shop/store/saga";
import proStatsReducer, {proStatsReducerKey} from "../containers/Pro/Stats/store/reducer";
import proStatsSaga, {proStatsSagaKey} from "../containers/Pro/Stats/store/saga";
import {useInjectReducers} from "../helpers/injectors/injectReducer";
import {useInjectSagas} from "../helpers/injectors/injectSaga";
import {initLanguage} from "../helpers/multilingual";
import ProRoutes from "../routes/ProRoutes";

const ProReducers = [
  {key: proStatsReducerKey, reducer: proStatsReducer},
  {key: commissionsReducerKey, reducer: commissionsReducer},
  {key: customersReducerKey, reducer: customersReducer},
  {key: networkReducerKey, reducer: networkReducer},
  {key: careerReducerKey, reducer: careerReducer},
  {key: proShopReducerKey, reducer: proShopReducer},
];

const ProSagas = [
  {key: proStatsSagaKey, saga: proStatsSaga},
  {key: commissionsSagaKey, saga: commissionsSaga},
  {key: customersSagaKey, saga: customersSaga},
  {key: networkSagaKey, saga: networkSaga},
  {key: careerSagaKey, saga: careerSaga},
  {key: proShopSagaKey, saga: proShopSaga},
];

const drawerWidth = 240;

function ProLayout(props) {

  initLanguage(props.match.params.lang || 'fr');

  useInjectReducers({key: 'proState', reducers: ProReducers});
  useInjectSagas({key: 'proSaga', sagas: ProSagas});

  return (
    <NoSsr>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Header/>

        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { md: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Toolbar />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <ProRoutes/>
          </LocalizationProvider>
        </Box>
      </Box>
    </NoSsr>
  );

}

export default ProLayout;
