import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import {useTheme} from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import FormValidator from "../../../../components/Validators/FormValidator";
import {SnackbarConfirm, SnackbarError} from "../../../../helpers/snackbar";
import SearchUserAutocomplete from "../../Users/components/SearchUserAutocomplete";
import {userService} from "../store/services";
const UserDeleteModal = ({user, open, setOpen}) => {
  const theme = useTheme();
  const history = useHistory();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [targetUser, setTargetUser] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleUserChange = (e, targetUser) => {
    setTargetUser(targetUser);
  };

  const handleSubmit = () => {
    SnackbarConfirm("Êtes-vous sûr de vouloir supprimer cet utilisateur ?", () => {
      userService.removeUser(user.id, targetUser).then(() => {
        setOpen(false);
        history.push(`/admin/`);
      })
        .catch(e => {
          console.log("e", e);
          SnackbarError(e?.error?.message);
        });
    });
  };

  return (
    <Dialog onClose={handleClose} open={open} fullScreen={fullScreen}>
      <DialogTitle id="responsive-dialog-title">Supprimer un utilisateur</DialogTitle>
      <DialogContent>
        <Card elevation={0}>
          <CardHeader
            subheader={"Vous pouvez choisir un autre utilisateur pour transférer les ventes et les clients"}
          />
          <CardContent>
            <FormValidator
              onSubmit={handleSubmit}
              onError={errors => console.log(errors)}
              noValidate
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SearchUserAutocomplete onChange={handleUserChange}/>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{color: '#FFFFFF'}}
                  >
                    Supprimer
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" color="textSecondary" align="center">
                    Notez que si l'utilisateur a des commandes, vous ne pourrez pas le supprimer.
                  </Typography>
                </Grid>
              </Grid>
            </FormValidator>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <Typography>
            Annuler
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserDeleteModal;
