import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { grey, purple } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import useMoment from "../../../../helpers/useMoment";


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiCard-root': {
      '& .MuiCardContent-root': {
        '& .MuiSvgIcon-root': {
          fill: purple[900],
          fontSize: '1.8em',
        },
        '&:hover': {
          backgroundColor: grey[50],
          cursor: 'pointer'
        }
      },
      height: '100%'
    },
    '& .MuiButtonBase-root': {
      padding: '0 10px'
    },
    '& .mention': {
      marginLeft: 20
    },
    '& .item': {
      '& .MuiGrid-container': {
        height: '100%'
      },
      '& .MuiCardContent-root': {
        height: '100%',
        padding: theme.spacing(1, 2)
      },
      '& .MuiCardContent-root:last-child': {
        paddingBottom: theme.spacing(1)
      },
      '& .headerNote': {
        borderBottom: `1px solid ${grey[400]}`,
      },
      '& .date': {
        fontSize: 14,
      },
      '& .note': {
        display: 'inline-block',
        borderRadius: 8,
        whiteSpace: 'pre-wrap',
      },
    },
  },
}));

const ProNotes = ({proNotes}) => {

  const classes = useStyles();
  const moment = useMoment();

  return (
    <Grid container spacing={1} className={classes.root}>
      {proNotes.map(proNote => (
        <Grid key={proNote.id} className={"item"} item xs={12} >
          <Card elevation={1} rounded="true" square={false}>
            <CardContent>
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={12} md={12}>
                  <Typography component="h3" variant={"h6"} gutterBottom color={"primary"}>{moment(proNote.consultationDate).format('LL')} : </Typography>
                </Grid>
                <Grid item xs={12} md={12} >
                  <Typography component="h4" variant={"h7"}>Sujets abordés :</Typography>
                </Grid>
                <Grid item xs={12} md={12} >
                  <Typography sx={{ whiteSpace: "pre-line" }}>{proNote?.content1}</Typography>
                </Grid>
                {proNote?.content2 && (
                  <>
                    <Grid item xs={12} md={12}>
                      <Typography component="h4" variant={"h7"}>Réactions et commentaires :</Typography>
                    </Grid>
                    <Grid item xs={12} md={12} >
                      <Typography sx={{whiteSpace: "pre-line"}}>{proNote?.content2}</Typography>
                    </Grid>
                  </>
                )}
                {proNote?.nextConsultationDate && (
                  <>
                    <Grid item xs={12} md={12}>
                      <Typography component="h4" variant={"h7"} color={"primary"}>Prochaine consultation : <Typography component={"span"} sx={{fontWeight: "bold"}}>{moment(proNote.nextConsultationDate).format('LL')}</Typography></Typography>
                    </Grid>
                    {proNote?.content3 && (
                      <>
                        <Grid item xs={12} md={12} >
                          <Typography component="h4" variant={"h7"}>Objectifs de la visite :</Typography>
                        </Grid>
                        <Grid item xs={12} md={12} >
                          <Typography sx={{ whiteSpace: "pre-line" }}>{proNote?.content3}</Typography>
                        </Grid>
                      </>
                    )}
                    {proNote?.content4 && (
                      <>
                        <Grid item xs={12} md={12} >
                          <Typography component="h4" variant={"h7"}>Actions à mener avant la visite :</Typography>
                        </Grid>
                        <Grid item xs={12} md={12} >
                          <Typography sx={{ whiteSpace: "pre-line" }}>{proNote?.content4}</Typography>
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default ProNotes;
