import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import GroupIcon from '@mui/icons-material/Group';
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import MemoryIcon from "@mui/icons-material/Memory";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import SettingsIcon from '@mui/icons-material/Settings';
import ViewListIcon from '@mui/icons-material/ViewList';
import {Box} from "@mui/material";
import Badge from "@mui/material/Badge";
import {grey} from "@mui/material/colors";
import Container from "@mui/material/Container";
import Drawer from "@mui/material/Drawer";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import {makeStyles} from "@mui/styles";

import theme from "../../../assets/styles/theme/theme";
import SignIn from "../../Authentication/components/SignIn";
import {authenticationActions} from "../../Authentication/store/actions";
import {
  isEmployee,
  isPro,
  isSessionControl,
  prescriptionCountSelector,
  userSelector
} from "../../Authentication/store/selectors";
import {shopActions} from "../Shop/store/actions";
import {isAccountOpenSelector} from "../Shop/store/selectors";

import Account from "./components/Account";
import Orders from "./components/Orders/Orders";
import Prescriptions from "./components/Prescriptions/Prescriptions.js";
import Settings from "./components/Settings";
import ShippingAddresses from "./components/ShippingAddresses/ShippingAddresses";

const useStyle = makeStyles(theme => ({
  root: {
    position: 'relative',
    '& .MuiDrawer-paper': {
      backgroundColor: grey[50],
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        flexShrink: 0,
      },
      '& img': {
        maxHeight: 120
      }
    }
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  expandIcons: {
    position: "absolute",
    top: "50vh",
    left: 0,
    cursor: "pointer"
  }
}));

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="account-tabpanel"
      hidden={value !== index}
      id={`account-vertical-tabpanel-${index}`}
      aria-labelledby={`account-vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: {xs: '8px 8px 24px', mw: '8px 16px 24px'},
          }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `account-vertical-tab-${index}`,
    'aria-controls': `account-vertical-tabpanel-${index}`,
  };
}

const AccountDrawer = () => {

  const classes = useStyle();
  const history = useHistory();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const userIsEmployee = useSelector(isEmployee);
  const userIsPro = useSelector(isPro);
  const userIsSessionControl = useSelector(isSessionControl);
  const open = useSelector(isAccountOpenSelector);
  const prescriptionCount = useSelector(prescriptionCountSelector);
  const [tabsIndex, setTabsIndex] = React.useState(0);
  const user = useSelector(userSelector);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const account = searchParams.get('account');
  const section = searchParams.get('section');
  /*

    //Reload user every 5 seconds to get updated data if an admin has changed it
    useEffect(() => {
      if(!open || !user) return;
      const interval = setInterval(() => {
        dispatch(authenticationActions.signInJwtRequest(Cookie.get('jwt')));
      }, 5000);
      return () => clearInterval(interval);
    },[open]);
  */
  useEffect(() => {
    if (account && account === "orders") {
      dispatch(shopActions.SetDrawersOpen("account", true));
      setTabsIndex(2);
    } else if(section && section === "signIn" || section === "signUp") {
      dispatch(shopActions.SetDrawersOpen("account", true));
    }
  }, [account, section]);

  const handleChange = (event, newValue) => {
    setTabsIndex(newValue);
  };
  const handleCloseDrawer = () => {
    dispatch(shopActions.SetDrawersOpen("account", false));
  };

  const handleSignOut = () => {
    dispatch(authenticationActions.signOut());
    dispatch(shopActions.SetDrawersOpen("account", false));
  };

  const handleLink = (url) => {
    dispatch(shopActions.SetDrawersOpen("account", false));
    history.push(`/${url}`);
  };

  return (
    <Drawer className={classes.root} anchor={'right'} open={open} onClose={() => handleCloseDrawer()}
      key={user?.id}>

      <Container
        maxWidth="sm"
        sx={{
          position: 'relative',
          height: "100vh",
          paddingLeft: '0px !important',
          paddingRight: '0px !important',
          overflowX: 'hidden',
          overflowY: 'scroll',
          background: '#EEF1F2'
        }}>
        {user
          ?
          <Box sx={{width: '100%', display: 'flex'}}>
            <Box
              sx={{
                height: '100%',
                pt: '0 !important',
                width: '90px',
                '& .MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon': {
                  width: '90px',
                  padding: 0
                }
              }}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={tabsIndex}
                onChange={handleChange}
                aria-label="account-drawer-vertical-tabs"
                sx={{
                  width: '90px',
                  height: "100%",
                  backgroundColor: '#EEF1F2'
                }}
              >
                <Tab icon={<AccountCircleIcon/>}
                  label={t("account")}
                  sx={{
                    color: '#7C909B',
                    fontSize: "10px",
                    fontFamily: "Montserrat"
                  }}
                  {...a11yProps(0)} />
                <Tab icon={<LocalShippingIcon/>}
                  label={t("addresses")}
                  sx={{
                    color: '#7C909B',
                    fontSize: "10px",
                    fontFamily: "Montserrat"
                  }}
                  {...a11yProps(1)} />
                <Tab icon={<ViewListIcon/>}
                  label={t("orders")}
                  sx={{
                    color: '#7C909B',
                    fontSize: "10px",
                    fontFamily: "Montserrat"
                  }}
                  {...a11yProps(2)} />
                <Tab
                  icon={
                    <Badge
                      color="error"
                      badgeContent={
                        prescriptionCount > 99
                          ? '99+'
                          : prescriptionCount > 0
                            ? prescriptionCount
                            : null
                      }
                    >
                      <ViewListIcon />
                    </Badge>
                  }
                  label={t("prescriptions")}
                  sx={{
                    color: '#7C909B',
                    fontSize: "10px",
                    fontFamily: "Montserrat"
                  }}
                  {...a11yProps(3)} />
                <Tab icon={<SettingsIcon/>}
                  label={t("settings")}
                  sx={{
                    color: '#7C909B',
                    fontSize: "10px",
                    fontFamily: "Montserrat"
                  }}
                  {...a11yProps(4)} />
                {userIsSessionControl && (
                  <Tab icon={<HighlightOffTwoToneIcon/>}
                    label={"Arrêter la session"}
                    onClick={handleSignOut}
                    sx={{
                      color: '#7C909B',
                      fontSize: "10px",
                      fontFamily: "Montserrat"
                    }}/>
                )}
                {userIsEmployee && (
                  <Tab
                    icon={<MemoryIcon/>}
                    label={"Admin"}
                    onClick={() => handleLink('admin')}
                    sx={{
                      color: '#7C909B',
                      fontSize: "10px",
                      fontFamily: "Montserrat"
                    }}/>
                )}
                {userIsEmployee && (
                  <Tab
                    icon={<MenuBookOutlinedIcon/>}
                    label={"Manager"}
                    onClick={() => handleLink('manager')}
                    sx={{
                      color: '#7C909B',
                      fontSize: "10px",
                      fontFamily: "Montserrat"
                    }}/>
                )}
                {userIsEmployee && (
                  <Tab
                    icon={<EventNoteOutlinedIcon/>}
                    label={"Logistique"}
                    onClick={() => handleLink('logistic/orders/todo')}
                    sx={{
                      color: '#7C909B',
                      fontSize: "10px",
                      fontFamily: "Montserrat"
                    }}/>
                )}
                {userIsEmployee && (
                  <Tab
                    icon={<AccountBalanceOutlinedIcon/>}
                    label={"Comptabilité"}
                    onClick={() => handleLink('accounting')}
                    sx={{
                      color: '#7C909B',
                      fontSize: "10px",
                      fontFamily: "Montserrat"
                    }}/>
                )}
                {userIsPro && (
                  <Tab
                    icon={<GroupIcon/>}
                    label={"Espace Pro"}
                    onClick={() => handleLink("pro/prescriptions")}
                    sx={{
                      fontSize: "10px",
                      fontFamily: "Montserrat",
                      color: theme.palette.primary.main,
                    }}

                  />
                )}
                <Tab icon={<MeetingRoomIcon/>}
                  label={t("signOut")}
                  onClick={handleSignOut}
                  sx={{
                    color: '#7C909B',
                    fontSize: "10px",
                    fontFamily: "Montserrat"
                  }}/>
              </Tabs>
            </Box>
            <Box
              sx={{
                p: '16px, 4px',
                background: '#fff',
                width: 'calc(100vw - 80px)',
                maxWidth: {mw: '100%', sm: '520px', md: '520px'},
                minHeight: '100vh',
              }}>
              <Box component={'div'} sx={{
                position: 'relative',
              }}>
                <CloseIcon onClick={handleCloseDrawer} sx={{
                  top: 16,
                  right: 16,
                  fontSize: 26,
                  cursor: 'pointer',
                  position: 'absolute',
                  color: '#7C909B',
                  background: '#ffffff',
                  borderRadius: '50%',
                  boxShadow: '2px 2px 8px #7C909B',
                  padding: '4px',
                }}/>
              </Box>
              <TabPanel value={tabsIndex} index={0}>
                <Typography sx={{
                  color: theme.palette.primary.main,
                  fontFamily: 'Open sans',
                  fontSize: "1.3rem",
                  p: '8px 0px 16px'
                }}>
                                    Mes informations
                </Typography>
                <Account/>
              </TabPanel>
              <TabPanel value={tabsIndex} index={1}>
                <Typography sx={{
                  color: theme.palette.primary.main,
                  fontFamily: 'Open sans',
                  fontSize: "1.3rem",
                  p: '8px 0px 16px'
                }}>
                  {t("addresses")}
                </Typography>
                <ShippingAddresses/>
              </TabPanel>
              <TabPanel value={tabsIndex} index={2}>
                <Typography sx={{
                  color: theme.palette.primary.main,
                  fontFamily: 'Open sans',
                  fontSize: "1.3rem",
                  p: '8px 0px 16px'
                }}>
                  {t("orders")}
                </Typography>
                <Orders/>
              </TabPanel>
              <TabPanel value={tabsIndex} index={3}>
                <Typography sx={{
                  color: theme.palette.primary.main,
                  fontFamily: 'Open sans',
                  fontSize: "1.3rem",
                  p: '8px 0px 16px'
                }}>
                  {t("prescriptions")}
                </Typography>
                <Prescriptions/>
              </TabPanel>
              <TabPanel value={tabsIndex} index={4}>
                <Typography sx={{
                  color: theme.palette.primary.main,
                  fontFamily: 'Open sans',
                  fontSize: "1.3rem",
                  p: '8px 0px 16px'
                }}>
                  {t("settings")}
                </Typography>
                <Settings/>
              </TabPanel>
            </Box>
          </Box>
          :
          <SignIn section={section}/>
        }
      </Container>
    </Drawer>
  );
};

export default AccountDrawer;