import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { getLangPath } from "../../../helpers/multilingual";
import ForgotPassword from "../ForgotPassword";
import SignUp from "../SignUp";
import { userSelector } from "../store/selectors";

import SignInForm from "./SignInForm";

const SignIn = (props) => {
  const history = useHistory();
  const loggedUser = useSelector(userSelector);
  const [section, setSection] = useState("signIn");

  useEffect(() => {
    if(loggedUser) {
      history.push(`${getLangPath()}${props.location && props.location.from ? props.location.from.pathname : ''}`);
    }
  }, [loggedUser]);

  useEffect(() => {
    if(props?.section) {
      switch (props.section) {
        case "signIn":
          setSection("signIn");
          break;
        case "forgotPassword":
          setSection("forgotPassword");
          break;
        case "signUp":
          setSection("signUp");
          break;
        default:
          setSection("signIn");
      }
    }
  }, [props?.section]);

  return (
    <>
      {section === "signIn" && (
        <SignInForm setSection={setSection}/>
      )}
      {section === "forgotPassword" && (
        <ForgotPassword setSection={setSection}/>
      )}
      {section === "signUp" && (
        <SignUp setSection={setSection}/>
      )}
    </>
  );
};

export default SignIn;
