import React, { useEffect, useState } from "react";
import {useDispatch} from "react-redux";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { withStyles } from '@mui/styles';
import i18next from "i18next";

import useMoment from "../../../../../helpers/useMoment";
import {shopActions} from "../../../Shop/store/actions.js";
import { shopService } from "../../../Shop/store/services";

import OrderDetails from "./components/OrderDetails";
import OrderFreeDetails from "./components/OrderFreeDetails";
import OrderHiddenDetails from "./components/OrderHiddenDetails";
import OrderRecommendationDetails from "./components/OrderRecommendationDetails";
import OrderStatus from "./components/OrderStatus";

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const Orders = () => {

  const moment = useMoment();
  const [orders, setOrders] = useState([]);
  const dispatch = useDispatch();


  useEffect(() => {

    shopService.getOrders(i18next.language).then((orders) => {
      setOrders(orders);
    });
    dispatch(shopActions.getPrescriptionsCountRequest());

  }, []);

  return (
    <>
      {orders && orders.length > 0
        ? orders?.map(order => (
          <Accordion key={order.id}>
            <AccordionSummary aria-controls={`panel1d-content-${order.id}`} id={`panel1d-header-${order.id}`}>
              <Grid container justify={"space-between"} alignItems={"center"}>
                <Grid item>
                  <Typography sx={{
                    fontFamily: 'Open sans',
                    fontSize: '0.9rem !important',
                    '& b': {
                      fontFamily: 'Montserrat',
                    }
                  }}>
                    <b>{moment(order.createdAt).format('LL')} {order.status === 'published' && (` - ${Math.round((parseFloat(order.totalPrice) + parseFloat(order.taxes))*100)/100} ${order.currency}`)}</b> - <OrderStatus order={order}/>
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              {(order.status === 'published' || order.status === 'gotBack') && (
                <OrderDetails order={order}/>
              )}
              {order.status === 'recommendation' && (
                <OrderRecommendationDetails order={order}/>
              )}
              {(order.status === 'free' || order.status === 'points' || order.status === 'giftCard' || order.status === 'points&giftCard') && (
                <OrderFreeDetails order={order}/>
              )}
              {(order.status === 'deferred' || order.status === 'resent') && (
                <OrderHiddenDetails order={order}/>
              )}
            </AccordionDetails>
          </Accordion>
        ))
        : <Typography sx={{
          fontFamily: 'Open sans',
          color: 'primary.light',
          fontSize: '0.9rem !important'
        }}>
            Vous n'avez pas encore passé de commande.
        </Typography>
      }
    </>
  );
};
export default Orders;
