import i18next from "i18next";
import { call, put, select,takeEvery } from 'redux-saga/effects';

import Cookie from "../../../../helpers/cookie";
import { getLangPath } from "../../../../helpers/multilingual";
import { SnackbarError, SnackbarFormattedError, SnackbarSuccess } from "../../../../helpers/snackbar";
import { history } from "../../../../helpers/store";
import {isLuxembourgForbidden, isSwissForbidden} from "../../../../helpers/utils";
import {SIGNIN_CREDENTIALS_SUCCESS, SIGNIN_JWT_SUCCESS} from "../../../Authentication/store/constants";

import { shopActions } from "./actions";
import * as actionsType from './constants';
import { shopService } from "./services";

export const shopSagaKey = 'shop';

const shopSelector = (state) => state.shop;

function* getUserShippingAddresses() {

  try {
    let {shippingAddresses} = yield call(shopService.getShippingAddresses);

    yield put(shopActions.getShippingAddressesSuccess(shippingAddresses));
  }
  catch (e) {
    yield put(shopActions.getShippingAddressesFailed());
  }
}

function* addUserShippingAddress(action) {

  try {
    const {shippingAddress} = yield call(shopService.addShippingAddress, action.shippingAddress);

    yield put(shopActions.addShippingAddressSuccess(shippingAddress));
    yield put(shopActions.setShippingAddressSelected(shippingAddress));
    //yield put(shopActions.reloadCartRequest(shippingAddress.country));
    yield call(SnackbarSuccess, i18next.t("addressAdded"));
  }
  catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(shopActions.addShippingAddressFailed());
  }
}

function* updateUserShippingAddress(action) {

  try {
    const {shippingAddress} = yield call(shopService.updateShippingAddress, action.shippingAddressId, action.shippingAddress);
    let {shippingAddresses} = yield call(shopService.getShippingAddresses);
    yield put(shopActions.getShippingAddressesSuccess(shippingAddresses));
    yield put(shopActions.updateShippingAddressSuccess(shippingAddress));
    yield call(SnackbarSuccess, i18next.t("addressUpdated"));
  }
  catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(shopActions.updateShippingAddressFailed());
  }
}

function* removeUserShippingAddress(action) {

  try {
    yield call(shopService.removeShippingAddress, action.shippingAddress);

    yield put(shopActions.removeShippingAddressSuccess(action.shippingAddress));
    yield call(SnackbarSuccess, i18next.t("addressRemoved"));
  }
  catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(shopActions.removeShippingAddressFailed());
  }
}

function* setDefaultUserShippingAddress(action) {

  try {
    yield call(shopService.setAsDefaultShippingAddress, action.shippingAddress);

    yield put(shopActions.setAsDefaultShippingAddressSuccess(action.shippingAddress));
    //yield put(shopActions.reloadCartRequest(action.shippingAddress.country));
    //yield call(SnackbarSuccess, i18next.t("addressSelectedAsDefault"));
  }
  catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(shopActions.setAsDefaultShippingAddressFailed());
  }
}

function* setDefaultUserBillingAddress(action) {

  try {
    yield call(shopService.setAsDefaultBillingAddress, action.billingAddress);

    yield put(shopActions.setAsDefaultBillingAddressSuccess(action.billingAddress));
    //yield put(shopActions.reloadCartRequest(action.shippingAddress.country));
    //yield call(SnackbarSuccess, i18next.t("addressSelectedAsDefault"));
  }
  catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(shopActions.setAsDefaultBillingAddressFailed());
  }
}

function* getProducts(action) {

  try {
    //const shop = yield select(shopSelector);
    const country = action.country ? action.country : 'LU';
    //const productsKey = `${action.section}-${action.sectionId}-${action.country}-${action.lang}`;
    const {products} = yield call(shopService.getProducts, action.lang, country, action.section, action.sectionId, action.offset, action.limit);
    yield put(shopActions.getProductsSuccess(action.lang, country, action.section, action.sectionId, products));
  }
  catch (e) {
    yield put(shopActions.getProductsFail());
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
  }
}

function* getEvents(action) {

  try {
    //const shop = yield select(shopSelector);
    const country = action.country ? action.country : 'LU';
    //const productsKey = `${action.section}-${action.sectionId}-${action.country}-${action.lang}`;
    const {products} = yield call(shopService.getEvents, action.lang, country, action.section, action.sectionId, action.offset, action.limit);
    yield put(shopActions.getEventsSuccess(action.lang, country, action.section, action.sectionId, products));
  }
  catch (e) {
    yield put(shopActions.getEventsFail());
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
  }
}

function* getTopProducts(action) {
  try {
    const country = action.country ? action.country : 'LU';
    const topProducts = yield call(shopService.getTopProducts, action.lang, country);
    yield put(shopActions.getTopProductsSuccess(topProducts));
  }
  catch (e) {
    yield put(shopActions.getTopProductsFail());
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
  }
}

function* getCatalogProducts(action) {

  try {
    const {products} = yield call(shopService.getCatalogProducts, action.lang);

    yield put(shopActions.getCatalogProductsSuccess(products));
  }
  catch (e) {
    yield put(shopActions.getCatalogProductsFail());
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
  }
}

function* searchProducts(action) {

  try {
    const country = action.country ? action.country : 'LU';
    let products = [];
    if(action.search.length >= 2) {
      products = yield call(shopService.searchProducts, action.lang, country, action.search);
      yield put(shopActions.searchProductsSuccess(country, products));
    }
  }
  catch (e) {
    yield put(shopActions.searchProductsFail());
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
  }
}

function* getProduct(action) {

  try {
    const product = yield call(shopService.getProduct, action.lang, action.country, action.productId, action.allowHidden);
    if(!product){
      yield put(shopActions.setShopProductRequestAnsweredEmpty(true));
    }
    yield put(shopActions.getProductSuccess(product));
  }
  catch (e) {
    yield put(shopActions.getProductFail());
    SnackbarError('Ce produit n\'existe pas');
  }
}

function* getCategories(action) {

  try {
    const categories = yield call(shopService.getCategories, action.lang);
    yield put(shopActions.getCategoriesSuccess(action.lang, categories));
  }
  catch (e) {
    yield put(shopActions.getCategoriesFail());
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
  }
}

function* getThemes(action) {

  try {
    const themes = yield call(shopService.getThemes, action.lang);
    yield put(shopActions.getThemesSuccess(action.lang, themes));
  }
  catch (e) {
    yield put(shopActions.getThemesFail());
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
  }
}

function* getBrands() {

  try {
    const brands = yield call(shopService.getBrands);
    yield put(shopActions.getBrandsSuccess(brands));
  }
  catch (e) {
    yield put(shopActions.getBrandsFail());
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
  }
}
/*

function* getCategory(action) {

  try {
    const category = yield call(shopService.getCategory, action.lang, action.categoryId);
    yield put(shopActions.getCategorySuccess(category));
  }
  catch (e) {
    yield put(shopActions.getCategoryFail());
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
  }
}
*/

function* saveCart() {

  const shop = yield select(shopSelector);
  // eslint-disable-next-line no-empty
  try { localStorage.setItem('cart', JSON.stringify(shop.cart.items)) } catch (e) {}
  if(shop?.cart?.items && Object.keys(shop.cart.items).length > 0) {
    yield put(shopActions.SetDrawersOpen("checkout", true));
  } else {
    yield put(shopActions.SetDrawersOpen("checkout", false));
  }
}

function* setCartFromOrder(action) {

  try {
    const items = yield call(shopService.getProductsByOrderId, i18next.language, action.orderId, action.country);
    try { localStorage.setItem('cart', JSON.stringify(items)) } catch (e) {
      yield put(shopActions.setCartFromOrderFail());
    }
    yield put(shopActions.setCartFromOrderSuccess(i18next.language, action.country, items));
  }
  catch (e) {
    yield put(shopActions.setCartFromOrderFail());
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
  }
}

function* addOrderFromPrescription(action) {

  try {
    const items = yield call(shopService.addOrderFromPrescription, action.prescriptionId);
    try { localStorage.setItem('cart', JSON.stringify(items)) } catch (e) {
      yield put(shopActions.addOrderFromPrescriptionFail());
    }
    yield put(shopActions.addOrderFromPrescriptionSuccess(items));
  }
  catch (e) {
    yield put(shopActions.addOrderFromPrescriptionFail());
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
  }
}

function* getPrescriptionsCount() {

  try {
    const prescriptionCount = yield call(shopService.getPrescriptionsCount);
    yield put(shopActions.getPrescriptionsCountSuccess(prescriptionCount));
  }
  catch (e) {
    yield put(shopActions.getPrescriptionsCountFail());
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
  }
}

function* findCart() {

  try {
    if( localStorage.getItem('cart') ) {
      return;
    }
    const items = yield call(shopService.findCart);
    try {
      if(items && Object.keys(items).length > 0) {
        localStorage.setItem('cart', JSON.stringify(items));
        yield put(shopActions.setCartFromOrderSuccess(i18next.language, null, items));
      }
    } catch (e) {
      yield put(shopActions.setCartFromOrderFail());
    }
  }
  catch (e) {
    yield put(shopActions.setCartFromOrderFail());
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
  }
}


function* signInSession(action) {

  try {
    const session = yield call(shopService.signInSession, action.consultantId, action.countryCode);
    Cookie.set('session', session);
    yield put(shopActions.signInSessionSuccess(session));

    if(action.redirect) {
      history.push(`${getLangPath()}${action.redirect}`);
    }
  }
  catch (e) {
    yield put(shopActions.signInSessionFail());
    if(e.error.type === 'session-error') {
      SnackbarError(i18next.t('errorMessageInvalidConsultantCode'));
    }
    else {
      SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
    }

  }
}

function* signInSessionByDirectLink(action) {

  try {
    const session = yield call(shopService.signInSessionByDirectLink, action.directLink, action.countryCode);
    Cookie.set('session', session);
    yield put(shopActions.signInSessionByDirectLinkSuccess(session));
  }
  catch (e) {
    yield put(shopActions.signInSessionByDirectLinkFail());
    //SnackbarError(i18next.t('errorMessageInvalidConsultantLink'))

  }
}

function* getCheckout(action) {

  try {
    const checkout = yield call(shopService.getCheckout, action.lang, action.shippingMethodId, action.shippingAddressId, action.shippingPickupPoint, action.items, action.useWallet, action.giftCard, action.promotionCode);

    // Checking if one product is forbidden for the country and setting the cart as forbidden
    let cartForbidden = false;

    if (checkout?.order.products) {
      for (let product of checkout.order.products) {
        if (isLuxembourgForbidden(product.originalProduct?.id, null, checkout.order.shippingCountry)) {
          product.forbidden = true;
          cartForbidden = true;
        }
        if (isSwissForbidden(product.id, checkout.order.shippingCountry)) {
          product.forbidden = true;
          cartForbidden = true;
        }
      }
    }

    yield put(shopActions.setCartForbidden(cartForbidden));
    yield put(shopActions.getCheckoutSuccess(checkout));
  }
  catch (e) {
    yield put(shopActions.getCheckoutFail());
    //SnackbarError(i18next.t('errorMessageAnErrorOccurred'))
  }
}

function* getShippingMethods(action) {

  try {
    const shippingMethods = yield call(shopService.getShippingMethods, action.country, action.zip, action.weight, action.orderPrice);
    yield put(shopActions.getShippingMethodsSuccess(shippingMethods));
  }
  catch (e) {
    yield put(shopActions.getShippingMethodsFail());
    SnackbarFormattedError(e.error);
  }
}

function* getOrders(action) {

  try {
    const orders = yield call(shopService.getOrders, action.lang);
    yield put(shopActions.getOrdersSuccess(orders));
  }
  catch (e) {
    yield put(shopActions.getOrdersFail());
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
  }
}

function* getGiftCards() {

  try {
    const getGiftCards = yield call(shopService.getGiftCards);
    yield put(shopActions.getGiftCardsSuccess(getGiftCards));
  }
  catch (e) {
    yield put(shopActions.getGiftCardsFail());
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
  }
}

function* addToFavorites(action) {

  try {
    yield call(shopService.addToFavorites, action.productId);
    yield put(shopActions.addToFavoritesSuccess(action.productId));
  }
  catch (e) {
    console.log(e);
    yield put(shopActions.getGiftCardsFail());
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
  }
}

function* removeFromFavorites(action) {

  try {
    yield call(shopService.removeFromFavorites, action.productId);
    yield put(shopActions.removeFromFavoritesSuccess(action.productId));
  }
  catch (e) {
    yield put(shopActions.getGiftCardsFail());
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
  }
}

export default function* watchShop() {

  yield takeEvery(actionsType.GET_SHIPPING_ADDRESSES_REQUEST, getUserShippingAddresses);
  yield takeEvery(actionsType.ADD_SHIPPING_ADDRESS_REQUEST, addUserShippingAddress);
  yield takeEvery(actionsType.UPDATE_SHIPPING_ADDRESS_REQUEST, updateUserShippingAddress);
  yield takeEvery(actionsType.REMOVE_SHIPPING_ADDRESS_REQUEST, removeUserShippingAddress);
  yield takeEvery(actionsType.SET_AS_DEFAULT_SHIPPING_ADDRESS_REQUEST, setDefaultUserShippingAddress);
  yield takeEvery(actionsType.SET_AS_DEFAULT_BILLING_ADDRESS_REQUEST, setDefaultUserBillingAddress);
  yield takeEvery(actionsType.GET_PRODUCTS_REQUEST, getProducts);
  yield takeEvery(actionsType.GET_EVENTS_REQUEST, getEvents);
  yield takeEvery(actionsType.GET_TOP_PRODUCTS_REQUEST, getTopProducts);
  yield takeEvery(actionsType.GET_CATALOG_PRODUCTS_REQUEST, getCatalogProducts);
  yield takeEvery(actionsType.SEARCH_PRODUCTS_REQUEST, searchProducts);
  yield takeEvery(actionsType.GET_PRODUCT_REQUEST, getProduct);
  yield takeEvery(actionsType.GET_CATEGORIES_REQUEST, getCategories);
  yield takeEvery(actionsType.GET_THEMES_REQUEST, getThemes);
  yield takeEvery(actionsType.GET_BRANDS_REQUEST, getBrands);
  //yield takeEvery(actionsType.GET_CATEGORY_REQUEST, getCategory);
  yield takeEvery(actionsType.CART_ADD_ITEM, saveCart);
  yield takeEvery(actionsType.CART_SET_ITEM_QUANTITY, saveCart);
  yield takeEvery(actionsType.SIGNIN_SESSION_REQUEST, signInSession);
  yield takeEvery(actionsType.SIGNIN_SESSION_BY_DIRECT_LINK_REQUEST, signInSessionByDirectLink);
  yield takeEvery(actionsType.GET_CHECKOUT_REQUEST, getCheckout);
  yield takeEvery(actionsType.GET_SHIPPING_METHODS_REQUEST, getShippingMethods);
  yield takeEvery(actionsType.CART_SET_FROM_ORDER_REQUEST, setCartFromOrder);
  yield takeEvery(actionsType.GET_ORDERS_REQUEST, getOrders);
  yield takeEvery(actionsType.GET_GIFT_CARDS_REQUEST, getGiftCards);
  yield takeEvery(actionsType.GET_GIFT_CARDS_REQUEST, getGiftCards);
  yield takeEvery(actionsType.ADD_TO_FAVORITES_REQUEST, addToFavorites);
  yield takeEvery(actionsType.REMOVE_FROM_FAVORITES_REQUEST, removeFromFavorites);
  yield takeEvery(actionsType.ADD_ORDER_FROM_PRESCRIPTION_REQUEST, addOrderFromPrescription);
  yield takeEvery(actionsType.GET_PRESCRIPTIONS_COUNT_REQUEST, getPrescriptionsCount);
  yield takeEvery(SIGNIN_CREDENTIALS_SUCCESS, findCart);
  yield takeEvery(SIGNIN_JWT_SUCCESS, findCart);
}
