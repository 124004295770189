import React from "react";
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';

import SignIn from "../containers/Authentication/components/SignIn";
import About from "../containers/Customer/About/About";
import Contact from "../containers/Customer/Contact";
import DroitsRetraction from "../containers/Customer/DroitsRetraction";
import Home from "../containers/Customer/Home/Home";
import Article from "../containers/Customer/Mag/Article";
import Articles from "../containers/Customer/Mag/Articles";
import MentionsLegales from "../containers/Customer/MentionsLegales";
import Pros from "../containers/Customer/Pros/Pros";
import ProtectionDonnes from "../containers/Customer/ProtectionDonnes";
import Event from "../containers/Customer/Shop/Event";
import Events from "../containers/Customer/Shop/Events";
import Product from "../containers/Customer/Shop/Product/Product";
import Search from "../containers/Customer/Shop/Search";
import Shop from "../containers/Customer/Shop/Shop";
import TermsOfSales from "../containers/Customer/TermsOfSales";

const CustomerRoutes = () => {

  const PaymentOnly = loadable(() => import("../containers/Customer/Shop/Checkout/PaymentOnly"));
  const Welcome = loadable(() => import("../containers/Authentication/Welcome"));
  const SignInJwt = loadable(() => import("../containers/Authentication/SignInJwt"));
  const ForgotPassword = loadable(() => import("../containers/Authentication/ForgotPassword"));
  const PasswordUpdate = loadable(() => import("../containers/Authentication/PasswordUpdate"));
  const EmailUpdate = loadable(() => import("../containers/Authentication/EmailUpdate"));
  const SignOut = loadable(() => import("../containers/Authentication/SignOut"));
  const MailingUnsubscribe = loadable(() => import("../containers/Customer/Mailing/MailingUnsubscribe"));
  const Error = loadable(() => import("../containers/Customer/Error"));

  return (
    <Switch>
      <Route exact path="/:lang(en|fr|nl)?" component={Home}/>
      <Route exact path="/:lang(en|fr|nl)?/mag/:url" component={Article}/>
      <Route exact path="/:lang(en|fr|nl)?/mag" component={Articles}/>
      <Route exact path="/:lang(en|fr|nl)?/about" component={About}/>
      <Route exact path="/:lang(en|fr|nl)?/health-professional" component={Pros}/>
      <Route exact path="/:lang(en|fr|nl)?/contact" component={Contact}/>
      <Route exact path="/:lang(en|fr|nl)?/contact/:id" component={Contact}/>
      <Route exact path="/:lang(en|fr|nl)?/auth/signin" component={SignIn}/>
      <Route exact path="/:lang(en|fr|nl)?/auth/signin/:userId/jwt/:jwt/redirect/:redirect+" component={SignInJwt}/>
      <Route exact path="/:lang(en|fr|nl)?/auth/forgot-password" component={ForgotPassword}/>
      <Route exact path="/:lang(en|fr|nl)?/auth/failed" component={SignIn}/>
      <Route exact path="/:lang(en|fr|nl)?/auth/signout" component={SignOut}/>
      <Route exact path="/:lang(en|fr|nl)?/auth/signout/:status" component={SignOut}/>
      <Route exact path="/:lang(en|fr|nl)?/auth/success/:jwt" component={SignIn}/>
      <Route exact path="/:lang(en|fr|nl)?/auth/welcome/:jwt" component={Welcome}/>
      <Route exact path="/:lang(en|fr|nl)?/auth/password/update/:jwt" component={PasswordUpdate}/>
      <Route exact path="/:lang(en|fr|nl)?/auth/email/update/:jwt" component={EmailUpdate}/>
      <Route exact path="/:lang(en|fr|nl)?/terms-of-sales" component={TermsOfSales}/>
      <Route exact path="/:lang(en|fr|nl)?/mentions-legales" component={MentionsLegales}/>
      <Route exact path="/:lang(en|fr|nl)?/droits-retractation" component={DroitsRetraction}/>
      <Route exact path="/:lang(en|fr|nl)?/protection-donnees" component={ProtectionDonnes}/>
      <Route exact path="/:lang(en|fr|nl)?/shop" component={Shop}/>
      <Route exact path="/:lang(en|fr|nl)?/shop/search/:search" component={Search}/>
      <Route exact path="/:lang(en|fr|nl)?/shop/checkout/payment/order/:orderId" component={PaymentOnly}/>
      <Route exact path="/:lang(en|fr|nl)?/shop/product/:productName/:productId" component={Product}/>
      <Route exact path="/:lang(en|fr|nl)?/shop/:section" component={Shop}/>
      <Route exact path="/:lang(en|fr|nl)?/shop/:section/:sectionName/:sectionId" component={Shop}/>
      <Route exact path="/:lang(en|fr|nl)?/events" component={Events}/>
      <Route exact path="/:lang(en|fr|nl)?/event/:productName/:productId" component={Event}/>
      <Route exact path="/:lang(en|fr|nl)?/events/category" component={Events}/>
      <Route exact path="/:lang(en|fr|nl)?/events/category/:sectionName/:sectionId" component={Events}/>
      <Route exact path="/:lang(en|fr|nl)?/mailings/unsubscribe/:email/:userId" component={MailingUnsubscribe}/>
      <Route path="*" component={Error}/>
    </Switch>
  );
};

export default CustomerRoutes;
